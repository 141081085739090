import { apiEndPoints, apiHeaders } from '../../../utils/apiConstants'
import customFetch from '../../../utils/customAxios'
import { AnyObject } from '../../../utils/utils'
import { SendOtpRequest, SendOtpResponse, UpdateProfileRequest, UpdateProfileResponse, VerifyOtpRequest, VerifyOtpResponse } from './signupDatamodal'

export const sendOtpThunk = async (body: SendOtpRequest, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(apiEndPoints.sendOtp, body)

		return { data: response.data } as { data: SendOtpResponse }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}

export const verifyOtpThunk = async (body: VerifyOtpRequest, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(apiEndPoints.verifyOtp, body)

		return { data: response.data } as { data: VerifyOtpResponse }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}

export const verifyEmailOtpThunk = async (body: VerifyOtpRequest, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(apiEndPoints.verifyEmailOtp, body, {
			withCredentials: true,
			headers: {
				[apiHeaders.preventDefaultToastSuccess]: true,
				[apiHeaders.preventDefaultToastFailure]: true,
			},
		})
		return { data: response.data } as { data: VerifyOtpResponse }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}

export const verifyMobileOtpThunk = async (body: VerifyOtpRequest, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.put(apiEndPoints.verifyMobileOtp, body)
		return { data: response.data } as { data: VerifyOtpResponse }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}

export const updateProfileThunk = async (body: UpdateProfileRequest, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.put(apiEndPoints.updateProfile, body)
		return { data: response.data } as { data: UpdateProfileResponse }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}

export const refreshTokenThunk = async (_: AnyObject, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(
			apiEndPoints.refreshToken,
			{},
			{
				headers: {
					[apiHeaders.preventDefaultLoader]: true,
					[apiHeaders.refreshApi]: true,
				},
				withCredentials: true,
			}
		)
		return { data: response.data } as { data: VerifyOtpResponse }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}

export const googleLoginCallbackThunk = async (body: { code: string }, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(apiEndPoints.googleCallback, body, { withCredentials: true })
		return { data: response.data }
	} catch (error) {
		return rejectWithValue(error)
	}
}
