import React, { Component, ErrorInfo, ReactNode } from 'react'
import { Box, Typography, Button } from '@mui/material'

interface Props {
	children: ReactNode
}

interface State {
	hasError: boolean
	error: Error | null
}

class ErrorBoundary extends Component<Props, State> {
	public state: State = {
		hasError: false,
		error: null,
	}

	public static getDerivedStateFromError(error: Error): State {
		return { hasError: true, error }
	}

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error('Uncaught error:', error, errorInfo)
	}

	public render() {
		if (this.state.hasError) {
			return (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						height: '100vh',
						textAlign: 'center',
						p: 3,
					}}
				>
					<Typography variant='h4' component='h1' gutterBottom>
						Oops! Something went wrong.
					</Typography>
					<Typography variant='body1' paragraph>
						We're sorry for the inconvenience. Please try refreshing the page or contact support if the problem persists.
					</Typography>
					<Button variant='contained' color='primary' onClick={() => window.location.reload()} sx={{ mt: 2 }}>
						Refresh Page
					</Button>
				</Box>
			)
		}

		return this.props.children
	}
}

export default ErrorBoundary
