// authSlice.ts

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GamesList } from './createPlaylistDatamodal'
import { createGamePlaylistThunk, fetchGamesListThunk, getS3LinkTouploadPlaylistImage, updateGamePlaylistThunk } from './gamePlaylistThunk'

// Define the shape of the authentication state
interface signupProps {
	gamesData: GamesList | null
}

// Initial state for the authentication slice
const initialState: signupProps = {
	gamesData: null,
}

// Async thunk to handle the sendotp  API call
export const fetchGamesList = createAsyncThunk('gamePlaylistSlice/fetchgames', fetchGamesListThunk)
export const getS3LinkToUpload = createAsyncThunk('gamePlaylistSlice/gets3link', getS3LinkTouploadPlaylistImage)
export const createGamesPlaylist = createAsyncThunk('gamePlaylistSlice/createPlaylist', createGamePlaylistThunk)

export const updateGamesPlaylist = createAsyncThunk('gamePlaylistSlice/updateGamesPlaylist', updateGamePlaylistThunk)

// Authentication slice with reducers and extraReducers for async actions
const gamePlaylistSlice = createSlice({
	name: 'gamePlaylistSlice',
	initialState,
	reducers: {
		setGamesData: (state, action) => {
			state.gamesData = action.payload
		},
	},
	extraReducers: (builder) => {
		// Handle the fulfilled case of the login API call
		builder.addCase(fetchGamesList.fulfilled, (state, action) => {
			state.gamesData = action.payload.data
		})
	},
})

export const { setGamesData } = gamePlaylistSlice.actions

export default gamePlaylistSlice.reducer
