import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
// import reportWebVitals from './reportWebVitals'
import { commonConfig } from './environment'
import { stringConstants } from './utils/commonConstants'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import { Provider } from 'react-redux'
import { store } from './app_features/store'
import { BrowserRouter } from 'react-router-dom'
import MuiThemeProvider from './custom_components/MuiThemeProvider'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'

// Check the environment variable to determine which CSS file to import
if (commonConfig.app.theme === stringConstants.default) {
	import('./resources/default/theme/palette1/css/index.css' as string)
	// import('./resources/default/theme/palette1/css/datePicker.css' as string)
	import('./resources/default/theme/typography1/fonts/fonts.css' as string)
} else {
	import('./resources/whitelabel/css/index.css' as string) // Import whitelabel CSS otherwise
	// import('./resources/whitelabel/css/datePicker.css' as string)
	import('./resources/whitelabel/fonts/fonts.css' as string)
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<I18nextProvider i18n={i18n}>
		<MuiThemeProvider>
			<Provider store={store}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</Provider>
		</MuiThemeProvider>
	</I18nextProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
