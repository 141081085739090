import { styled, Box, Avatar } from '@mui/material'
import React from 'react'

const HexagonShape = styled(Box)<{ widthsx?: string; heightsx?: string }>(({ widthsx, heightsx }) => ({
	width: widthsx || '24px',
	height: heightsx || '24px',
	position: 'relative',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
	backgroundColor: '#d3d3d3', // hexagon color
	// Using mask-image for hexagon with rounded corners
	maskImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpolygon points='50 0, 93 25, 93 75, 50 100, 7 75, 7 25' rx='3' ry='3'/%3E%3C/svg%3E")`,
	maskSize: 'cover',
	webkitMaskImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpolygon points='50 0, 93 25, 93 75, 50 100, 7 75, 7 25' rx='3' ry='3'/%3E%3C/svg%3E")`,
}))

const StyledAvatar = styled(Avatar)<{ widthsx?: string; heightsx?: string }>(({ widthsx, heightsx }) => ({
	width: widthsx || '24px',
	height: heightsx || '24px',
}))

interface ProfileProps {
	imageUrl?: string
	index?: number
	widthsx?: string
	heightsx?: string
	alt?: string
}

export const HexAvatar: React.FC<ProfileProps> = ({ imageUrl, index, widthsx = '24px', heightsx = '24px', alt = 'User avatar' }) => (
	<HexagonShape
		sx={{
			m: index === 0 ? '0 0px' : '0 -5px',
			width: widthsx,
			height: heightsx,
		}}
		widthsx={widthsx}
		heightsx={heightsx}
	>
		<StyledAvatar src={imageUrl} variant='square' widthsx={widthsx} heightsx={heightsx} alt={alt}>
			{!imageUrl && (
				<svg
					viewBox='0 0 24 24'
					fill='white'
					style={{
						width: `calc(${widthsx} * 0.7)`, // 70% of dynamic width
						height: `calc(${heightsx} * 0.7)`, // 70% of dynamic height
					}}
				>
					<path d='M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z' />
				</svg>
			)}
		</StyledAvatar>
	</HexagonShape>
)
