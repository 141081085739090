import React, { ReactElement, useLayoutEffect, useRef } from 'react'
import { Box, Table, TableContainer } from '@mui/material'
import CustomTableHeader, { TableColumnProps } from '../../../../custom_components/CustomTableHeader'
import { localeKeys } from '../../../../resources/localeConstants'
import CustomTableBody, { defaultTableCellData, TableCellProps, TableCellType, TableRowProps } from '../../../../custom_components/CustomTableBody'
import { commonStyles } from '../../../../app_features/commonStyles'
import { SetTableColumnProps } from '../../../../app_features/types'
import { LeaderBoard } from './dataModal'
import { iconKeys } from '../../../../resources/resourceConstants'
import { getUserFromLocalStorage } from '../../../../utils/localStorage'
import { UserProfile } from '../../../home_thunk/homeDatamodal'
import { useLocalize } from '../../../../custom_components/CustomHooks'

const styles = {
	tableContainer: {
		width: '100%',
		// minHeight: '310px',
	},
}

export interface LeaderBoardListTableProps {
	containerHeight: number
	handleRowClick: (event: React.MouseEvent<unknown>, rowIndex: number, rowId: string) => void
	data: LeaderBoard[] | null
	columns: TableColumnProps[]
	setColumns: SetTableColumnProps
}

/**
 * Represents a leaderboard table displaying employee rank and stats.
 * @function
 * @returns {ReactElement} The rendered LeaderBoardListTable component.
 */
const LeaderBoardListTable = (props: LeaderBoardListTableProps): ReactElement => {
	const tableRef = useRef<HTMLDivElement>(null)
	const user = getUserFromLocalStorage() as UserProfile
	const { columns, setColumns, data, handleRowClick } = props
	const dataNew = data || []
	const localize = useLocalize()

	const rows = dataNew.map((item, index) => {
		const dataSet: Record<string, TableCellProps> = {
			[localeKeys.rank]: {
				...defaultTableCellData,
				iconName:
					Number(item.rank) === 1
						? iconKeys.rankOne
						: Number(item.rank) === 2
						? iconKeys.rankTwo
						: Number(item.rank) === 3
						? iconKeys.rankThree
						: iconKeys.crown,
				type: TableCellType.rank,
				value: item.rank,
			},
			[localeKeys.name]: {
				...defaultTableCellData,
				value: item._id === user._id ? localize(localeKeys.you) : item.name || '',
				privateUrl: '',
				type: TableCellType.avatar,
			},
			[localeKeys.gender]: {
				...defaultTableCellData,
				value: Number(item.gender) === 1 ? 'Male' : Number(item.gender) === 2 ? 'Female' : Number(item.gender) === 3 ? 'Others' : 'NA',
			},
			[localeKeys.profession]: {
				...defaultTableCellData,
				value: item.profession || '',
			},
			[localeKeys.country]: {
				...defaultTableCellData,
				value: item.country || '',
			},
			[localeKeys.state]: {
				...defaultTableCellData,
				value: item.state || '',
			},
			[localeKeys.city]: {
				...defaultTableCellData,
				value: item.city || '',
			},
			[localeKeys.score]: {
				...defaultTableCellData,
				value: item.score || '',
				// iconName: iconKeys.coin,
				// type: TableCellType.iconText,
			},
		}

		const row: TableRowProps = {
			index: index,
			data: [],
			_id: item?._id,
		}

		columns.map((column) => row.data.push({ ...dataSet[column.id], id: column.id }))
		return row
	})

	useLayoutEffect(() => {
		if (tableRef.current) {
			const currentScrollX = tableRef.current.scrollLeft
			tableRef.current.scrollTo({
				top: 0,
				left: currentScrollX,
				behavior: 'auto',
			})
		}
	}, [props?.data])

	return (
		<Box sx={styles.tableContainer}>
			<TableContainer
				ref={tableRef}
				sx={{
					maxHeight: `300px`,
					...commonStyles.commonTableContainerStyle,
				}}
			>
				<Table aria-labelledby='tableTitle' stickyHeader>
					<CustomTableHeader columns={columns} setColumns={setColumns} />

					{rows?.length > 0 ? <CustomTableBody rows={rows} columns={columns} handleRowClick={handleRowClick} userId={user._id} /> : null}
				</Table>
			</TableContainer>
		</Box>
	)
}

export default LeaderBoardListTable
