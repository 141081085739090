import { apiEndPoints, apiHeaders } from '../../utils/apiConstants'
import customFetch from '../../utils/customAxios'
import { AnyObject } from '../../utils/utils'
import { VerifyOtpRequest } from '../signUp/signup_thunk/signupDatamodal'
import { CompleteProfileRequest } from './homeDatamodal'

export const fetchProfileThunk = async () => {
	const response = await customFetch.get(apiEndPoints.fetchProfile, {
		headers: {
			[apiHeaders.preventDefaultLoader]: true,
		},
	})

	// Return the response data with the specified type
	return { data: response.data }
}

// export const completeUserProfileThunk = async () => {
// 	const response = await customFetch.get(apiEndPoints.completeUserProfile)

// 	// Return the response data with the specified type
// 	return { data: response.data }
// }

export const completeUserProfileThunk = async (body: CompleteProfileRequest, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(apiEndPoints.completeUserProfile, body)

		return { data: response.data }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}

export const sendReautheticateOtpThunk = async () => {
	try {
		const response = await customFetch.post(apiEndPoints.sendReauthentication, {})

		return { data: response.data }
	} catch (error: AnyObject) {
		return null
	}
}

export const verifyReautheticateOtpThunk = async (body: VerifyOtpRequest, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(apiEndPoints.verifyReauthentication, body, {
			headers: {
				[apiHeaders.preventDefaultToastSuccess]: false,
				[apiHeaders.preventDefaultToastFailure]: true,
			},
		})

		return { data: response.data }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}

export const deleteUserThunk = async () => {
	try {
		const response = await customFetch.post(apiEndPoints.deleteUser, {})

		return { data: response.data }
	} catch (error: AnyObject) {
		return null
	}
}
