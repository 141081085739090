import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
// Import your JSON files directly
import enNs1 from './resources/default/locales/en/ns1.json'
import enWhiteLabelNs1 from './resources/whitelabel/locales/en/ns1.json'
import esNs1 from './resources/default/locales/es/ns1.json'
import esWhiteLabelNs1 from './resources/whitelabel/locales/es/ns1.json'

import { commonConfig } from './environment'
import { stringConstants } from './utils/commonConstants'
import { CustomJSONObject } from './utils/utils'
// Import white-label resources if needed
// import enWhiteLabelNs1 from './resources/whitelabel/locales/en/ns1.json'
// import esWhiteLabelNs1 from './resources/whitelabel/locales/es/ns1.json'

export const defaultNS = 'ns1'

const resources = (): CustomJSONObject => {
	if (commonConfig.app.theme !== stringConstants.default) {
		return {
			en: {
				ns1: enWhiteLabelNs1,
			},
			es: {
				ns1: esWhiteLabelNs1,
			},
		}
	}

	return {
		en: {
			ns1: enNs1,
		},
		es: {
			ns1: esNs1,
		},
	}
}

// If you need to switch resources based on theme, you can do it here
// if (commonConfig.app.theme !== stringConstants.default) {
//   resources.en.ns1 = enWhiteLabelNs1
//   resources.es.ns1 = esWhiteLabelNs1
// }

i18next
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: process.env.NODE_ENV === 'development',
		fallbackLng: 'en',
		defaultNS,
		resources: resources(),
		interpolation: {
			escapeValue: false,
		},
	})

export default i18next
