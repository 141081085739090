import React, { useState } from 'react'
import { Box, Button, useMediaQuery, useTheme } from '@mui/material'

interface TabButtonGroupProps {
	labels: string[]
	onTabClick: (label: string) => void
}

const TabButtonGroup: React.FC<TabButtonGroupProps> = ({ labels, onTabClick }) => {
	const [selected, setSelected] = useState<string>(labels[0])
	const isSmallDevice = useMediaQuery('(max-width: 1000px)')
	const theme = useTheme()

	const isMd = useMediaQuery(theme.breakpoints.down('md'))

	const handleClick = (label: string) => {
		setSelected(label)
		onTabClick(label) // Trigger the callback with the clicked label
	}

	return (
		<Box
			display='flex'
			justifyContent='center'
			gap={isMd ? 1 : 2}
			flexWrap={isSmallDevice ? 'wrap' : 'nowrap'} // Enable wrapping on small screens
		>
			{labels.map((label) => (
				<Button
					key={label}
					variant={selected === label ? 'contained' : 'outlined'}
					onClick={() => handleClick(label)}
					sx={{
						borderRadius: '50px',
						textTransform: 'none',
						color: selected === label ? 'white' : 'text.primary',
						backgroundColor: selected === label ? 'background.background2' : 'background.surface',
						borderColor: 'border.main',
						fontWeight: '700',
						padding: isMd ? '4px 8px' : '8px 16px',
						'&:hover': {
							backgroundColor: selected === label ? 'background.background2' : 'background.surface',
						},
						width: isMd ? '60px' : '124px',
						height: isMd ? '30' : '42px',
						fontSize: '12px',
					}}
				>
					{label}
				</Button>
			))}
		</Box>
	)
}

export default TabButtonGroup
