import { apiEndPoints, apiHeaders } from '../../../../utils/apiConstants'
import customFetch from '../../../../utils/customAxios'
import { AnyObject } from '../../../../utils/utils'
import { GameEndSessionRequest, GameStartSessionRequest, LeaderBoardRequest } from './signleGameDatamodal'

export const startGameThunk = async (body: GameStartSessionRequest, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(apiEndPoints.startGameSession, body)

		return { data: response.data }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}

export const endGameThunk = async (body: GameEndSessionRequest, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(apiEndPoints.endGameSession, body, {
			headers: {
				[apiHeaders.preventDefaultLoader]: true,
			},
		})

		return { data: response.data }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}

export const leaderBoardThunk = async (body: LeaderBoardRequest, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(apiEndPoints.fetchLeaderboard, body, {
			headers: {
				[apiHeaders.preventDefaultLoader]: true,
			},
		})
		return { data: response.data }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}

export const leaderBoardDialogThunk = async (body: LeaderBoardRequest, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(apiEndPoints.fetchLeaderboard, body)

		return { data: response.data }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}

export const fetchGamePreferenceThunk = async (body: { gameId: string }) => {
	const response = await customFetch.post(apiEndPoints.fetchGamePreference, body)
	return { data: response.data }
}

export const updateGamePreferenceThunk = async (body: { gameId: string; showInstructions: boolean }) => {
	const response = await customFetch.put(apiEndPoints.updateGamePreference, body)
	return { data: response.data }
}
