import * as React from 'react'
import { styled } from '@mui/material/styles'
import Switch, { SwitchProps } from '@mui/material/Switch'

const IOSSwitch = styled((props: SwitchProps) => <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />)(({ theme }) => ({
	width: 52,
	height: 26,
	padding: 0,
	'& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 2,
		transitionDuration: '300ms',
		'&.Mui-checked': {
			transform: 'translateX(26px)',
			color: theme.palette.common.white,
			'& + .MuiSwitch-track': {
				backgroundColor: theme.palette.text.subHeading,
				opacity: 1,
				border: 0,
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.5,
			},
		},
		'&.Mui-focusVisible .MuiSwitch-thumb': {
			color: theme.palette.common.white,
			border: '6px solid',
			borderColor: 'common.white',
		},
		'&.Mui-disabled .MuiSwitch-thumb': {
			color: theme.palette.common.white,
		},
		'&.Mui-disabled + .MuiSwitch-track': {
			opacity: 0.7,
		},
	},
	'& .MuiSwitch-thumb': {
		boxSizing: 'border-box',
		width: 22,
		height: 22,
		// color: theme.palette.greyNew.main,
	},
	'& .MuiSwitch-track': {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.border.lightBorder,
		opacity: 1,
		transition: theme.transitions.create(['background-color'], {
			duration: 500,
		}),
	},
}))

// Props for configuring an iOS-style switch component
interface IOSSwitchComponentProps {
	checked: boolean // Flag indicating whether the switch is checked
	onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void // Callback function invoked when the switch state changes
	disable?: boolean
}

/**
 * CustomSwitch: A custom switch component that wraps an iOS-style switch.
 * @param checked - Flag indicating whether the switch is checked.
 * @param onChange - Callback function invoked when the switch state changes.
 * @returns ReactElement representing the custom switch component.
 */
const CustomSwitch: React.FC<IOSSwitchComponentProps> = ({ checked, onChange, disable }) => {
	return <IOSSwitch checked={checked} onChange={onChange} disabled={disable} />
}

export default CustomSwitch
