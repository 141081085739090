import axios from 'axios'
import { commonConfig } from '../environment'
import i18next from 'i18next'
import { apiHeaders } from './apiConstants'
import {
	getAuthTypeFromLocalStorage,
	getTokenFromLocalStorage,
	removeAuthTypeFromLocalStorage,
	removeTokenFromLocalStorage,
	removeUserFromLocalStorage,
} from './localStorage'
import { logoutApi, setIsRefreshing } from '../app_features/current_user/currentUserSlice'
import { routePaths } from '../routes/routeKeys'
import { localeKeys } from '../resources/localeConstants'
import { logger } from '../logger'
import { AnyObject, decryptDataWithIv, deviceTypes, encryptDataWithIv, getDeviceType, isTokenExpired } from './utils'
import { refreshToken } from '../home/signUp/signup_thunk/signupSlice'

export const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
//get the devicetype web/ios/android
const deviceType = getDeviceType()

// Create an instance of Axios with custom configurations
const customFetch = axios.create({
	baseURL: commonConfig.api.baseUrl,
	headers: {
		Accept: apiHeaders.acceptType,
	},
	timeout: 30000,
})

// Queue to store the requests while the token is being refreshed
let requestQueue: AnyObject[] = []

export const processQueue = (error: AnyObject, token: string | null = null) => {
	requestQueue.forEach((prom) => {
		if (error) {
			prom.reject(error) // Reject the request if refresh failed
		} else {
			prom.resolve(token) // Retry the request with the new token
		}
	})
	requestQueue = [] // Clear the queue after processing
}

export const refreshAccessToken = async (store: AnyObject) => {
	store.dispatch(setIsRefreshing(true)) // Set isRefreshing to true in Redux
	try {
		const newData = await store.dispatch(refreshToken(''))

		// Process the queue of requests that were waiting for the refresh token
		processQueue(null, newData.payload.data.data.accessToken) // Process the queue with the new token
	} catch (error) {
		processQueue(error, null) // Reject all queued requests if refresh fails
	} finally {
		store.dispatch(setIsRefreshing(false)) // Reset isRefreshing to false
	}
}

// Function to initialize interceptors
export const initializeInterceptors = (store: AnyObject) => {
	// Add request interceptors
	customFetch.interceptors.request.use(
		(config) => {
			const isRefreshing = store.getState().currentUser.isRefreshing

			const token: string | null = getTokenFromLocalStorage()
			const accessType: string | null = getAuthTypeFromLocalStorage()

			if (token) {
				config.headers[apiHeaders.accessToken] = token
			}

			if (accessType) {
				config.headers[apiHeaders.accessType] = accessType
			}

			config.headers[apiHeaders.today] = new Date().toISOString()
			config.headers[apiHeaders.deviceType] = deviceType ?? deviceTypes.web
			config.headers[apiHeaders.timezone] = timezone
			config.headers[apiHeaders.language] = i18next.language

			// Adding Cache-Control Header
			config.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate'
			config.headers['Pragma'] = 'no-cache'
			config.headers['Expires'] = '0'

			if (!config.headers[apiHeaders.preventDefaultLoader]) {
				store.dispatch({ type: 'currentUser/updateIsLoading', payload: true })
				store.dispatch({ type: 'currentUser/setIsFetchingData', payload: true })
			}

			if (commonConfig.app.environment !== 'development' && !config.headers[apiHeaders.uploadToS3]) {
				try {
					if (config.data) {
						logger.log(config.data)
						config.data = { data: encryptDataWithIv(config.data, commonConfig.keys.reqEncryptKey, commonConfig.keys.reqEncryptIv) }
					}
				} catch (e) {
					logger.log('ecryption failed', e)
				}
			}

			if (token && isTokenExpired(token) && !isRefreshing) {
				refreshAccessToken(store)
			} else if (isRefreshing && !config.headers[apiHeaders.refreshApi]) {
				return new Promise((resolve, reject) => {
					requestQueue.push({ resolve, reject })
				})
					.then((token) => {
						config.headers[apiHeaders.accessToken] = token
						return config // Return the modified config, not the Axios response
					})
					.catch((err) => Promise.reject(err))
			}

			return config // Ensure the request interceptor returns the config
		},
		(error) => {
			return Promise.reject(error)
		}
	)

	// Add response interceptors
	customFetch.interceptors.response.use(
		(response) => {
			if (commonConfig.app.environment !== 'development' && !response.config.headers[apiHeaders.uploadToS3]) {
				try {
					const decryptedRes = decryptDataWithIv(response.data, commonConfig.keys.resDecryptKey, commonConfig.keys.resDecryptIv)
					response.data = decryptedRes
					logger.log(decryptedRes)
				} catch (e) {
					logger.log('decryption failed', e)
				}
			}

			if (!response.config.headers[apiHeaders.preventDefaultToastSuccess]) {
				if (response?.data?.message) {
					store.dispatch({
						type: 'currentUser/updateToastData',
						payload: {
							showToast: true,
							title: '',
							subTitle: response?.data?.message,
							isSuccess: true,
						},
					})
				}
			}

			if (!response.config.headers[apiHeaders.preventDefaultLoader]) {
				store.dispatch({ type: 'currentUser/updateIsLoading', payload: false })
				store.dispatch({ type: 'currentUser/setIsFetchingData', payload: false })
			}

			if (response.config.headers[apiHeaders.preventDefaultLoader]) {
				store.dispatch({ type: 'currentUser/setIsFetchingData', payload: false })
			}

			delete response?.config?.headers[apiHeaders.preventDefaultLoader]
			delete response?.config?.headers[apiHeaders.preventDefaultToastSuccess]

			return response
		},
		(error) => {
			if (error?.response?.status === 401) {
				requestQueue = []
				store.dispatch(logoutApi(''))
				removeTokenFromLocalStorage()
				removeAuthTypeFromLocalStorage()
				removeUserFromLocalStorage()
				window.location.href = routePaths.home
				store.dispatch({ type: 'logout' })
			}

			if (commonConfig.app.environment !== 'development' && !error.response.config.headers[apiHeaders.uploadToS3]) {
				try {
					const decryptedRes = decryptDataWithIv(error.response.data, commonConfig.keys.resDecryptKey, commonConfig.keys.resDecryptIv)
					error.response.data = decryptedRes

					logger.log(decryptedRes)
				} catch (e) {
					logger.log('Error while decrypting data', e)
				}
			}

			const errorMessage =
				error.code === 'ECONNABORTED'
					? localeKeys.timeoutError
					: error?.response?.data?.message ??
					  error?.response?.data?.error ??
					  error?.response?.data?.errors ??
					  error?.message ??
					  localeKeys.crashError

			error.errorMessage = errorMessage

			if (error?.response?.status !== 410 && !error.config.headers[apiHeaders.preventDefaultToastFailure]) {
				if (errorMessage) {
					store.dispatch({
						type: 'currentUser/updateToastData',
						payload: {
							showToast: true,
							title: '',
							subTitle: errorMessage,
							isSuccess: false,
						},
					})
				}
			}

			if (!error.config.headers[apiHeaders.preventDefaultLoader]) {
				store.dispatch({ type: 'currentUser/updateIsLoading', payload: false })
				store.dispatch({ type: 'currentUser/setIsFetchingData', payload: false })
			}

			delete error?.config?.headers[apiHeaders.preventDefaultLoader]
			delete error?.config?.headers[apiHeaders.preventDefaultToastFailure]

			return Promise.reject(error)
		}
	)
}

export default customFetch
