import React, { useEffect, useState } from 'react'
import { Box, Typography, List, ListItem, ListItemButton, useTheme } from '@mui/material'
import { typographyKeys } from '../../resources/resourceConstants'
import { localeKeys } from '../../resources/localeConstants'
import { useLocalize } from '../../custom_components/CustomHooks'

interface Game {
	_id: string
	gameName: string
}

interface GamesListRightProps {
	games: Game[]
	onSelectGame: (id: string) => void
}

const GamesListRight: React.FC<GamesListRightProps> = ({ games, onSelectGame }) => {
	const [selectedGameId, setSelectedGameId] = useState<string>(games[0]?._id || '')
	const localize = useLocalize()
	const theme = useTheme()
	const handleSelectGame = (id: string) => {
		setSelectedGameId(id)
		onSelectGame(id)
	}

	useEffect(() => {
		if (games.length > 0) {
			setSelectedGameId(games[0]._id)
			onSelectGame(games[0]._id)
		}
	}, [games])
	const gamelength = games.length

	return (
		<Box sx={{ boxShadow: '1px 0px 7px 1px #67784d', borderRadius: '8px', maxHeight: 'calc(100vh - 100px)', overflow: 'auto' }}>
			<Typography
				variant={typographyKeys.h5}
				sx={{ textAlign: 'center', color: 'text.defaultHeading', p: '10px', borderRadius: '8px 8px 0 0', bgcolor: 'background.surface' }}
			>
				{localize(localeKeys.gamesList)}
			</Typography>
			<List sx={{ p: 0 }}>
				{games.map((game, index) => (
					<ListItem key={game._id} disablePadding>
						<ListItemButton
							onClick={() => handleSelectGame(game._id)}
							selected={game._id === selectedGameId}
							sx={{
								borderBottomLeftRadius: index === gamelength - 1 ? '8px' : '0',
								borderBottomRightRadius: index === gamelength - 1 ? '8px' : '0',
								p: '10px 12px',

								background:
									game._id === selectedGameId
										? `linear-gradient(90deg, ${theme.palette.gradient.yellowish1} 0%, ${theme.palette.gradient.yellowish2} 100%)`
										: index % 2 === 0
										? theme.palette.background.default
										: theme.palette.background.surface,
								'&:hover': {
									bgcolor: 'background.background2',
								},
							}}
						>
							<Typography
								variant={typographyKeys.body1}
								sx={{ color: game._id === selectedGameId ? 'common.black' : 'text.defaultHeading' }}
							>
								{index + 1}. {game.gameName}
							</Typography>
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Box>
	)
}

export default GamesListRight
