import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { completeUserProfileThunk, deleteUserThunk, fetchProfileThunk, sendReautheticateOtpThunk, verifyReautheticateOtpThunk } from './homeThunk'
import { addUserToLocalStorage } from '../../utils/localStorage'
import { CompleteProfileFormModal, ProfileFormModal, UserProfile } from './homeDatamodal'
import { Option } from '../../custom_components/CustomSingleSelect'

interface AuthState {
	isCompletedLogin: boolean
	userData: UserProfile | null
	completeProfileForm: CompleteProfileFormModal
	profileForm: ProfileFormModal
}

const initialState: AuthState = {
	isCompletedLogin: false,
	userData: null,
	completeProfileForm: {
		dob: null,
		country: null,
		state: null,
		city: null,
		gender: null,
		profession: '',
	},
	profileForm: {
		fullName: '',
		displayName: '',
		emailId: '',
		phone: '',
		dob: null,
		gender: null,
		country: null,
		state: null,
		city: null,
		profession: '',
	},
}
export const fetchProfile = createAsyncThunk('fetchProfile/homeAuth', fetchProfileThunk)

export const completeUserProfileApi = createAsyncThunk('completeUserProfileApi/homeAuth', completeUserProfileThunk)
export const sendReautheticateOtp = createAsyncThunk('completeUserProfileApi/sendReautheticateOtp', sendReautheticateOtpThunk)

export const verifyReautheticateOtp = createAsyncThunk('completeUserProfileApi/verifyReautheticateOtp', verifyReautheticateOtpThunk)

export const deleteUser = createAsyncThunk('completeUserProfileApi/deleteUser', deleteUserThunk)

const homeSlice = createSlice({
	name: 'homeAuth',
	initialState,
	reducers: {
		setIsCompletedLogin: (state, action: PayloadAction<boolean>) => {
			state.isCompletedLogin = action.payload
		},
		setCompleteProfileData: (state, action: PayloadAction<{ key: keyof CompleteProfileFormModal; value: Option | null | string | Date }>) => {
			const { key, value } = action.payload
			if (key === 'profession') {
				state.completeProfileForm[key] = value as string
			} else if (key === 'dob') {
				state.completeProfileForm[key] = value as Date | null
			} else {
				state.completeProfileForm[key] = value as Option | null
			}
		},
		setProfileData: (state, action: PayloadAction<Partial<ProfileFormModal>>) => {
			state.profileForm = { ...state.profileForm, ...action.payload }
		},

		resetCompleteProfileForm: (state) => {
			state.completeProfileForm = initialState.completeProfileForm
		},
		resetProfileForm: (state) => {
			state.profileForm = initialState.profileForm
		},
	},

	extraReducers: (builder) => {
		// Handle the fulfilled case of the login API call
		builder.addCase(fetchProfile.fulfilled, (state, action) => {
			addUserToLocalStorage(action.payload.data.data)
			state.userData = action.payload.data.data
		})
		builder.addCase(completeUserProfileApi.fulfilled, (state, action) => {
			addUserToLocalStorage(action.payload.data.data)
			state.userData = action.payload.data.data
		})
	},
})

export const { setIsCompletedLogin, setCompleteProfileData, resetCompleteProfileForm, setProfileData, resetProfileForm } = homeSlice.actions
export default homeSlice.reducer
