/* eslint-disable no-mixed-operators */
import React from 'react'
import { Box, IconButton, Typography, Select, MenuItem } from '@mui/material'
import { ChevronLeft, ChevronRight, ExpandMore } from '@mui/icons-material'
import { typographyKeys } from '../resources/resourceConstants'
import { localeKeys } from '../resources/localeConstants'
import { useLocalize } from './CustomHooks'

interface CustomPaginationProps {
	currentPage: number
	totalPages: number
	rowsPerPage: number
	totalCount: number
	onPageChange: (page: number) => void
	onRowsPerPageChange: (rows: number) => void
	positionNotRequired?: boolean
	rowsPerPageOptions?: number[]
}

export default function CustomPagination({
	currentPage,
	totalPages,
	rowsPerPage,
	totalCount,
	onPageChange,
	onRowsPerPageChange,
	positionNotRequired,
	rowsPerPageOptions,
}: CustomPaginationProps) {
	const startIndex = (currentPage - 1) * rowsPerPage + 1
	const endIndex = Math.min(currentPage * rowsPerPage, totalCount)
	const localize = useLocalize()
	// const theme = useTheme()

	const renderPageNumbers = () => {
		const pageNumbers = []
		const maxVisiblePages = 3

		pageNumbers.push(
			<IconButton
				key={1}
				onClick={() => onPageChange(1)}
				sx={{
					backgroundColor: 1 === currentPage ? 'background.greenbg' : 'transparent',
					borderRadius: 0,
					minWidth: '32px',
					height: '32px',
					padding: 0,
					'&:hover': {
						backgroundColor: 'background.greenbg',
					},
					borderRight: '1px solid',
					borderRightColor: 'border.lightBorder',
				}}
			>
				<Typography variant={typographyKeys.h5} sx={{ color: 1 === currentPage ? 'text.subHeading' : 'icon.main' }}>
					{1}
				</Typography>
			</IconButton>
		)

		if (currentPage > maxVisiblePages) {
			pageNumbers.push(
				<Box
					key='ellipsis-start'
					sx={{
						backgroundColor: 'transparent',
						borderRadius: 0,
						minWidth: '32px',
						height: '32px',
						padding: 0,
						borderRight: '1px solid',
						borderRightColor: 'border.lightBorder',
					}}
				>
					<Typography variant={typographyKeys.h5} sx={{ color: 'icon.main', mx: 1, mt: '6px', ml: '10px' }}>
						...
					</Typography>
				</Box>
			)
		}

		for (let i = Math.max(2, currentPage - 1); i <= Math.min(currentPage + 1, totalPages - 1); i++) {
			pageNumbers.push(
				<IconButton
					key={i}
					onClick={() => onPageChange(i)}
					sx={{
						backgroundColor: i === currentPage ? 'background.greenbg' : 'transparent',
						borderRadius: 0,
						minWidth: '32px',
						height: '32px',
						padding: 0,
						'&:hover': {
							backgroundColor: 'background.greenbg',
						},
						borderRight: '1px solid',
						borderRightColor: 'border.lightBorder',
					}}
				>
					<Typography variant={typographyKeys.h5} sx={{ color: i === currentPage ? 'text.subHeading' : 'icon.main' }}>
						{i}
					</Typography>
				</IconButton>
			)
		}

		if (currentPage < totalPages - maxVisiblePages + 1) {
			pageNumbers.push(
				<Box
					key='ellipsis-end'
					sx={{
						backgroundColor: 'transparent',
						borderRadius: 0,
						minWidth: '32px',
						height: '32px',
						padding: 0,
						borderRight: '1px solid',
						borderRightColor: 'border.lightBorder',
					}}
				>
					<Typography variant={typographyKeys.h5} sx={{ color: 'icon.main', mt: '6px', ml: '10px' }}>
						...
					</Typography>
				</Box>
			)
		}

		if (totalPages > 1) {
			pageNumbers.push(
				<IconButton
					key={totalPages}
					onClick={() => onPageChange(totalPages)}
					sx={{
						backgroundColor: totalPages === currentPage ? 'background.greenbg' : 'transparent',
						borderRadius: 0,
						minWidth: '32px',
						height: '32px',
						padding: 0,
						'&:hover': {
							backgroundColor: 'background.greenbg',
						},
						borderRight: '1px solid',
						borderRightColor: 'border.lightBorder',
					}}
				>
					<Typography variant={typographyKeys.h5} sx={{ color: totalPages === currentPage ? 'text.subHeading' : 'icon.main' }}>
						{totalPages}
					</Typography>
				</IconButton>
			)
		}

		return pageNumbers
	}

	// const rowsPerPageOptions = [10, 40, 80, 100]

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: { xs: 'column', sm: 'column', md: 'row' },
				justifyContent: 'space-between',
				alignItems: { xs: 'stretch', sm: 'stretch', md: 'center' },
				padding: '4px 15px',
				width: '100%',
				position: positionNotRequired ? 'relative' : 'sticky',
				bottom: 0,
				left: 0,
				right: 0,
				backgroundColor: 'background.default',
				zIndex: 1,
			}}
		>
			{/* Left-side Showing X-Y of Z selector */}
			<Box
				display='flex'
				flexWrap='wrap'
				alignItems='center'
				px={2}
				mb={{ xs: 0, sm: 0, md: 0 }}
				justifyContent={{ xs: 'center', sm: 'center', md: 'flex-start' }}
			>
				<Typography sx={{ color: 'text.primary' }} variant={typographyKeys.subtitle1}>
					{localize(localeKeys.showing)}
				</Typography>
				<Select
					value={rowsPerPage}
					onChange={(e) => onRowsPerPageChange(Number(e.target.value))}
					IconComponent={ExpandMore}
					renderValue={() => `${startIndex}-${endIndex}`}
					sx={{
						color: '#fff',
						'&.MuiOutlinedInput-root fieldset': { border: 'none' },
						'& .MuiSelect-icon': { color: '#fff' },
					}}
					MenuProps={{
						PaperProps: {
							sx: {
								backgroundColor: 'background.surface',
								backgroundImage: 'none',
							},
						},
					}}
				>
					{(rowsPerPageOptions || [10, 40, 80, 100]).map((rows) => (
						<MenuItem
							key={rows}
							value={rows}
							sx={{
								color: 'text.primary',
								backgroundColor: 'background.surface',
								'&:hover': {
									backgroundColor: 'background.greenbg',
								},
								'&.Mui-selected': {
									backgroundColor: 'background.background2',
									'&:hover': {
										backgroundColor: 'background.greenbg',
									},
								},
							}}
						>
							<Typography sx={{ color: 'inherit', mr: 1 }} variant={typographyKeys.subtitle1}>
								1 - {Math.min(rows, totalCount)}
							</Typography>
						</MenuItem>
					))}
				</Select>
				<Typography sx={{ color: 'text.primary', mr: 1 }} variant={typographyKeys.subtitle1}>
					{localize(localeKeys.of)}
				</Typography>
				<Typography sx={{ color: 'text.primary', mr: 1 }} variant={typographyKeys.h5}>
					{totalCount}
				</Typography>
			</Box>

			{/* Updated Page Navigation */}
			<Box
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					alignItems: 'center',
					justifyContent: { xs: 'center', sm: 'center', md: 'flex-end' },
					borderRadius: '4px',
					backgroundColor: 'background.surface',
					border: '1px solid',
					borderColor: 'border.lightBorder',
					width: { xs: '100%', sm: '100%', md: 'auto' },
				}}
			>
				<IconButton
					onClick={() => onPageChange(currentPage - 1)}
					disabled={currentPage === 1}
					sx={{
						color: '#9da3ae',
						backgroundColor: 'transparent',
						borderRadius: 0,
						minWidth: '32px',
						height: '32px',
						padding: 0,
						borderRight: '1px solid',
						borderRightColor: 'border.lightBorder',
						'&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.08)' },
					}}
				>
					<ChevronLeft />
				</IconButton>
				{renderPageNumbers()}
				<IconButton
					onClick={() => onPageChange(currentPage + 1)}
					disabled={currentPage === totalPages}
					sx={{
						color: '#9da3ae',
						backgroundColor: 'transparent',
						borderRadius: 0,
						minWidth: '32px',
						height: '32px',
						padding: 0,
						'&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.08)' },
					}}
				>
					<ChevronRight />
				</IconButton>
			</Box>
		</Box>
	)
}
