import { useTranslation } from 'react-i18next'
import { PaletteOptions, TypographyOptions } from '../app_features/types'
import { logger } from '../logger'
import breakpoints from '../resources/theme/breakpoints'
import { stringConstants } from '../utils/commonConstants'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../app_features/store'
import { commonConfig } from '../environment'
import { useEffect, useState } from 'react'

export const getServerTypography = (): string => {
	//use this function to fetch data from login response and retur the typography from here
	return 'typography2'
}

export const getServerColorTheme = () => {
	//use this function to fetch data from login response and retur the color palette from here
	return 'palette2'
}

/**
 * Function to asynchronously load theme modules and set the theme options.
 */
export const getTheme = async (lightMode: boolean, isMobile: boolean) => {
	//get user from login response
	const mobileView = isMobile
	const user = false

	try {
		const typography1 = await import(`../resources/default/theme/typography1/typography`)
		const typography2 = await import(`../resources/default/theme/typography2/typography`)
		const whitelabelTypography = await import(`../resources/whitelabel/theme/typography/typography`)
		const palette1 = await import(`../resources/default/theme/palette1/mode`)

		const palette2 = await import(`../resources/default/theme/palette2/mode`)
		const whitelablePalette = await import(`../resources/whitelabel/theme/palette/mode`)

		const typographyOptions: TypographyOptions = {
			typography1: mobileView ? typography1.mobileTypography : typography1.deskTopTypography,
			typography2: mobileView ? typography2.mobileTypography : typography2.deskTopTypography,
			whitelabel: mobileView ? whitelabelTypography.mobileTypography : whitelabelTypography.deskTopTypography,
			// Add more options as needed
		}

		const paletteOptions: PaletteOptions = {
			palette1: lightMode ? palette1.light : palette1.dark,
			palette2: lightMode ? palette2.light : palette2.dark,
			whitelabel: lightMode ? whitelablePalette.light : whitelablePalette.dark,
			// Add more options as needed
		}

		const paletteCssOptions = {
			palette1: () => {
				import('../resources/default/theme/palette1/css/index.css' as string)
			},
			palette2: () => {
				import('../resources/default/theme/palette2/css/index.css' as string)
			},
			whitelabel: () => {
				import('../resources/whitelabel/css/index.css' as string) // Import whitelabel CSS otherwise
			},
			// Add more options as needed
		}

		const fontCssOptions = {
			typography1: () => {
				import('../resources/default/theme/typography1/fonts/fonts.css' as string)
			},
			typography2: () => {
				import('../resources/default/theme/typography2/fonts/fonts.css' as string)
			},
			whitelabel: () => {
				import('../resources/whitelabel/fonts/fonts.css' as string)
			},
			// Add more options as needed
		}

		let newThemeOption = {
			...typographyOptions.typography1,
			...paletteOptions.palette1,
		}

		if (commonConfig.app.theme !== stringConstants.default) {
			newThemeOption = {
				...typographyOptions.whitelabel,
				...paletteOptions.whitelabel,
			}
		} else if (user) {
			const typography = getServerTypography()
			const colorTheme = getServerColorTheme()

			const getFontCss = fontCssOptions[typography as keyof typeof fontCssOptions]
			const getPaletteCss = paletteCssOptions[colorTheme as keyof typeof paletteCssOptions]

			newThemeOption = { ...typographyOptions[typography], ...paletteOptions[colorTheme] }
			getFontCss()
			getPaletteCss()
		}

		return { ...newThemeOption, breakpoints }
	} catch (error) {
		logger.log('Error loading theme modules :', error)
		return null
	}
}

/**
 * Custom hook for localization
 *
 * @returns A function that translates the provided key using the i18next translation hook (useTranslation).
 * If the key is undefined or not found, an empty string is returned.
 */
export const useLocalize = (): ((key: string | undefined) => string) => {
	const { t } = useTranslation()

	return (key: string | undefined): string => {
		// Check if key is defined before attempting translation
		return key !== undefined ? t(key) : ''
	}
}

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

// Generic hook for debouncing any type of value
export function useDebounce<T>(value: T, delay: number): T {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState<T>(value)

	useEffect(() => {
		// Set debouncedValue to value (passed in) after the specified delay
		const handler = setTimeout(() => {
			setDebouncedValue(value)
		}, delay)

		return () => {
			clearTimeout(handler)
		}
	}, [value, delay])

	return debouncedValue
}
