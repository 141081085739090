import { Backdrop, Box } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import Lottie from 'lottie-react'
import { commonConfig } from '../environment'

const path = commonConfig.app.theme === 'default' ? 'default' : 'whitelabel'

interface LottieData {
	default: any
}

const Loader = (): ReactElement => {
	const [lottieData, setLottieData] = useState<LottieData | null>(null)

	useEffect(() => {
		const loadLottieData = async () => {
			try {
				const module: LottieData = await import(`../resources/${path}/images/lottie/loader.json`)
				setLottieData(module)
			} catch (error) {
				// Fallback to default Lottie if the dynamic import fails
				const defaultModule: LottieData = await import('../resources/default/images/lottie/loader.json')
				setLottieData(defaultModule)
			}
		}

		loadLottieData()
	}, [])

	return (
		<Box>
			<Backdrop
				style={{ backgroundColor: 'rgba(0, 0, 0, 0.08)', zIndex: 99999 }}
				classes={{
					root: 'MuiBackdrop-root-loader',
				}}
				open={true}
			>
				{lottieData && (
					<Lottie
						animationData={lottieData.default}
						loop={true}
						autoplay={true}
						style={{ height: 200, width: 200 }}
						rendererSettings={{
							preserveAspectRatio: 'xMidYMid slice',
						}}
					/>
				)}
			</Backdrop>
		</Box>
	)
}

export default Loader
