import { apiEndPoints, apiHeaders } from '../../utils/apiConstants'
import customFetch from '../../utils/customAxios'
import { AnyObject } from '../../utils/utils'
import { AdherenceReq } from './dataModal'

export const viewDashboardThunk = async () => {
	const response = await customFetch.post(
		apiEndPoints.viewDashboard,
		{},
		{
			headers: {
				[apiHeaders.preventDefaultLoader]: true,
			},
		}
	)
	return { data: response.data }
}

export const fetchAdherenceThunk = async (body: AdherenceReq, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(apiEndPoints.adherenceData, body, {
			headers: {
				[apiHeaders.preventDefaultLoader]: true,
			},
		})

		return { data: response.data }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}
