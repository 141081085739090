import { apiEndPoints } from '../../utils/apiConstants'
import customFetch from '../../utils/customAxios'
import { ListMyFriendsReq } from './dataModal'

// Define the thunk function for inviting a friend
export const inviteAFriendThunk = async (body: { friendDetails: { emailId: string; fullName: string }[] }) => {
	const response = await customFetch.post(apiEndPoints.inviteAFriend, body)
	return { data: response.data }
}

export const listFriendsSentReceivedThunk = async (body: { type: string }) => {
	const response = await customFetch.post(apiEndPoints.listFriendsSentReceived, body)
	return { data: response.data }
}

export const ignoreAcceptFriendApiThunk = async (body: { inviterId: string; status: string }) => {
	const response = await customFetch.post(apiEndPoints.ignoreAcceptFriend, body)
	return { data: response.data }
}

export const listMyFriendsApiThunk = async (body: ListMyFriendsReq) => {
	const response = await customFetch.post(apiEndPoints.listMyFriends, body)
	return { data: response.data }
}

export const unFriendThunk = async (body: { friendId: string }) => {
	const response = await customFetch.post(apiEndPoints.unFriend, body)
	return { data: response.data }
}

export const ignoreAcceptFriendEmailThunk = async (body: { type: string; token: string; action: string }) => {
	const response = await customFetch.post(apiEndPoints.ignoreAcceptFriendFromEmail, body)
	return { data: response.data }
}
