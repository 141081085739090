import React from 'react'
import { Box } from '@mui/material'
import CustomSingleSelect, { Option } from '../../custom_components/CustomSingleSelect'

import { localeKeys } from '../../resources/localeConstants'
import { useLocalize } from '../../custom_components/CustomHooks'
import { flexStyles } from '../../app_features/flexStyles'

interface Game {
	_id: string
	gameName: string
}

interface GameListSelectProps {
	games: Game[] | null
	onSelectGame: (id: string) => void
	selectedGameId: string | null
}

export default function GameListSelect({ games, onSelectGame, selectedGameId }: GameListSelectProps) {
	const localize = useLocalize()

	const gameOptions: Option[] = games
		? games.map((game) => ({
				id: game._id,
				label: game.gameName,
		  }))
		: []

	const handleSelectGame = (option: Option | null) => {
		if (option) {
			onSelectGame(option.id)
		}
	}

	const selectedOption = selectedGameId ? gameOptions.find((option) => option.id === selectedGameId) || null : null

	return (
		<Box sx={{ ...flexStyles.flexColumnJustifyAlignCenter, width: '100%', maxWidth: '220px' }}>
			<CustomSingleSelect
				name='gameSelect'
				placeholder={localize(localeKeys.games)}
				options={gameOptions}
				onSelect={handleSelectGame}
				value={selectedOption}
				disableTyping
				innerHeight={35}
			/>
		</Box>
	)
}
