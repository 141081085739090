// import { Theme } from '@mui/material'

/**
 * This file contains all the styles which are common across the application
 */
export const commonStyles = {
	hideScroll: {
		'::-webkit-scrollbar': {
			display: 'none !important',
		},
		'::-moz-scrollbar': {
			display: 'none !important',
		},
		'::-ms-scrollbar': {
			display: 'none !important',
		},
	},
	typographyTextNextLine: {
		wordWrap: 'break-word',
	},

	commonTableRowStyle: {
		cursor: 'pointer',
	},

	commonTableBodyStyle: {
		backgroundColor: 'greyNew.darker',
	},
	disableTableScroll: {
		// overflowX: 'hidden',
		overflow: 'auto',
		// overflowY: 'hidden',
		msOverflowStyle: 'none' /* hide scrollbar on IE and Edge */,
		scrollbarWidth: 'none' /* hide scrollbar on Firefox */,
		'&::-webkit-scrollbar:horizontal': {
			display: 'none' /* hide scrollbar on Chrome and Safari */,
		},
	},
	commonTableContainerStyle: {
		backgroundColor: 'globalColors.white',
		overflow: 'auto',
		// overflowY: 'hidden',
		msOverflowStyle: 'none' /* hide scrollbar on IE and Edge */,
		scrollbarWidth: 'none' /* hide scrollbar on Firefox */,
		'&::-webkit-scrollbar:horizontal': {
			display: 'none' /* hide scrollbar on Chrome and Safari */,
		},
		// minWidth: '500px',
		borderRadius: '5px',
		borderTopLeftRadius: '10px',
		borderTopRightRadius: '10px',
		border: '1px solid ',
		borderColor: 'background.secondary',
	},
}
