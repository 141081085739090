import { apiEndPoints, apiHeaders } from '../../../utils/apiConstants'
import customFetch from '../../../utils/customAxios'
import { AnyObject } from '../../../utils/utils'
import { GamesProgressReq, GamesRequest } from './gamesDataModal'

export const fetchGamesThunk = async (body: GamesRequest, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(apiEndPoints.fetchGames, body, {
			headers: {
				[apiHeaders.preventDefaultLoader]: true,
			},
		})

		return { data: response.data }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}

export const fetchGamesProgressThunk = async (body: GamesProgressReq, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(apiEndPoints.fetchGamesProgress, body, {
			headers: {
				[apiHeaders.preventDefaultLoader]: true,
			},
		})

		return { data: response.data }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}

export const allGamesYearlyDataApiThunk = async (body: { gameId: string }) => {
	console.log(apiEndPoints.allGamesYearlyData, 'askcasoaljkj')
	const response = await customFetch.post(apiEndPoints.allGamesYearlyData, body)
	return { data: response.data }
}
