// store.ts

import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit'
import currentUserSlice from './current_user/currentUserSlice'
import { AnyObject } from '../utils/utils'
import { initializeInterceptors } from '../utils/customAxios'
import homeSlice from '../home/home_thunk/homeSlice'
import signupSlice from '../home/signUp/signup_thunk/signupSlice'
import gamePlaylistSlice from '../home/create_playlist/gamePlaylistSlice'
import playlistSlice from '../home/games/playlist/playlist_thunk/playlistSlice'
import gamesSlice from '../home/games/games_thunk/gamesSlice'
import singleGameSlice from '../home/games/single_game/singleGame_thunk/singleGameSlice'
import friendsSlice from '../home/friends_overall/slice'
import dashboardSlice from '../home/dashboard/slice'

const appReducer = combineReducers({
	currentUser: currentUserSlice,
	homeAuth: homeSlice,
	signUpFlow: signupSlice,
	games: gamesSlice,
	singleGame: singleGameSlice,
	createPlaylist: gamePlaylistSlice,
	playlist: playlistSlice,
	friends: friendsSlice,
	dashboard: dashboardSlice,
})

const rootReducer = (state: AnyObject, action: AnyObject) => {
	if (action.type === 'logout') {
		state = undefined // This clears the entire state
	}
	return appReducer(state, action)
}

export const store = configureStore({
	reducer: rootReducer,
	devTools: process.env.REACT_APP_ENV !== 'production',
})

// Initialize interceptors after creating the store
initializeInterceptors(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
