import { apiEndPoints } from '../../utils/apiConstants'
import customFetch from '../../utils/customAxios'
import { AnyObject } from '../../utils/utils'
import { CreateGamePlaylistReq, FetchGamesRequest, GetS3LinkReq, UpdateGamePlaylistReq } from './createPlaylistDatamodal'

export const fetchGamesListThunk = async (body: FetchGamesRequest, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(apiEndPoints.listAllGames, body)

		return { data: response.data }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}

export const getS3LinkTouploadPlaylistImage = async (body: GetS3LinkReq, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(apiEndPoints.getS3LinkForPlaylistImage, body)

		return { data: response.data }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}

export const createGamePlaylistThunk = async (body: CreateGamePlaylistReq, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(apiEndPoints.createGamesPlaylist, body)

		return { data: response.data }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}

export const updateGamePlaylistThunk = async (body: UpdateGamePlaylistReq, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(apiEndPoints.updateGamesPlaylist, body)

		return { data: response.data }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}
