// authSlice.ts

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { allGamesYearlyDataApiThunk, fetchGamesProgressThunk, fetchGamesThunk } from './gamesThunk'
import { AllGamesYearlyDataResponse, GameResponse, GamesProgress } from './gamesDataModal'

// Define the shape of the authentication state
interface signupProps {
	gamesData: GameResponse[] | null
	gamesProgressData: GamesProgress[] | null
	activeTab: number
	totalGameCount: number
	allGamesYearlyData: AllGamesYearlyDataResponse | null
}

// Initial state for the authentication slice
const initialState: signupProps = {
	gamesData: null,
	gamesProgressData: null,
	activeTab: 0,
	totalGameCount: 0,
	allGamesYearlyData: null,
}

// Async thunk to handle the sendotp  API call
export const fetchGames = createAsyncThunk('fetchGames/games', fetchGamesThunk)
export const fetchGameProgress = createAsyncThunk('fetchGames/progress', fetchGamesProgressThunk)

export const allGamesYearlyDataApi = createAsyncThunk('fetchGames/yearlyData', allGamesYearlyDataApiThunk)

// Authentication slice with reducers and extraReducers for async actions
const GamesSlice = createSlice({
	name: 'games',
	initialState,
	reducers: {
		setGamesData: (state, action) => {
			state.gamesData = action.payload
		},

		setActiveTab: (state, action) => {
			state.activeTab = action.payload
		},
		setAllGamesYearlyData: (state, action) => {
			state.allGamesYearlyData = action.payload
		},
	},
	extraReducers: (builder) => {
		// Handle the fulfilled case of the login API call
		builder.addCase(fetchGames.fulfilled, (state, action) => {
			state.gamesData = action.payload.data.data
		})

		builder.addCase(fetchGameProgress.fulfilled, (state, action) => {
			state.gamesProgressData = action.payload.data.data.leaderboards
			state.totalGameCount = action.payload.data.data.totalGameCount
		})

		builder.addCase(allGamesYearlyDataApi.fulfilled, (state, action) => {
			state.allGamesYearlyData = action.payload.data.data
		})

		// // Handle the rejected case of the login API call
		// builder.addCase(fetchGames.rejected, (state, action) => {
		// 	if (action.payload === localeKeys.networkError) {
		// 		state.error = localeKeys.unableToConnect
		// 	} else {
		// 		state.error = action.payload as string
		// 	}
		// })
	},
})

export const { setGamesData, setActiveTab, setAllGamesYearlyData } = GamesSlice.actions

export default GamesSlice.reducer
