import React from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { iconKeys, typographyKeys } from '../../resources/resourceConstants'
import { localeKeys } from '../../resources/localeConstants'
import { useLocalize } from '../../custom_components/CustomHooks'
import { flexStyles } from '../../app_features/flexStyles'
import { HexAvatar } from '../../custom_components/CustomHexaAvatar'
import CustomIcon from '../../custom_components/CustomIcon'

interface userRankeDetailsProps {
	name?: string
	rank: string
	score: string
	profileUrl: string
}

const UserRankDetails: React.FC<userRankeDetailsProps> = ({ rank, score, profileUrl }) => {
	const localize = useLocalize()
	const theme = useTheme()

	// const isMd = useMediaQuery(theme.breakpoints.down('md'))
	const isSm = useMediaQuery(theme.breakpoints.down('sm'))

	return (
		<>
			<Box
				sx={{
					overflow: 'auto',
					msOverflowStyle: 'none',
					scrollbarWidth: 'none',
					'&::-webkit-scrollbar:horizontal': {
						display: 'none' /* hide scrollbar on Chrome and Safari */,
					},
				}}
			>
				<Box
					sx={{
						...flexStyles.flexRowCenter,
						border: '0.5px solid',
						borderColor: 'border.main',
						borderRadius: '10px',
						bgcolor: 'background.success',
						// minWidth: '340px',
					}}
				>
					<Box
						sx={{
							bgcolor: 'background.success',
							borderRadius: '10px 0 0 10px',
							padding: isSm ? '6px 10px' : '6.5px 20px',
						}}
					>
						<Typography variant={typographyKeys.h4} color='white'>
							{localize(localeKeys.you)}
						</Typography>
					</Box>
					<Box
						sx={{
							...flexStyles.flexRowCenter,
							bgcolor: 'background.greenBG',
							padding: isSm ? '0px 10px' : '0px 20px',
							borderRadius: '0 10px 10px 0',
							flex: 1,
							justifyContent: 'space-around',
							gap: isSm ? '10px' : '20px',
						}}
					>
						{/* <Box sx={{ ...flexStyles.flexRowAlignCenter, gap: '8px' }}> */}

						{/* </Box> */}
						<Box sx={{ ...flexStyles.flexRowAlignCenter, gap: '8px' }}>
							<HexAvatar imageUrl={profileUrl} index={0} widthsx={'24px'} heightsx={'24px'} />
							<Typography variant={typographyKeys.subtitle1} color='text.secondary'>
								{localize(localeKeys.rank)}:
							</Typography>
							<Box sx={{ ...flexStyles.flexRowAlignCenter, gap: '4px' }}>
								<CustomIcon name={iconKeys.crown} svgStyle='height: 16px; width: 16px;margin-top:5px' />
								<Typography variant={typographyKeys.h4} sx={{ color: 'text.defaultHeading' }}>
									{rank || 0}
								</Typography>
							</Box>
						</Box>
						<Box sx={{ ...flexStyles.flexRowAlignCenter, gap: '8px' }}>
							<Typography variant={typographyKeys.subtitle1} color='text.secondary'>
								{localize(localeKeys.points)}:
							</Typography>
							<Box sx={{ ...flexStyles.flexRowAlignCenter, gap: '4px' }}>
								{/* <CustomIcon name={iconKeys.coinIcon} svgStyle='height: 16px; width: 16px; margin-top:5px' /> */}
								<Typography variant={typographyKeys.button} sx={{ color: 'text.success' }}>
									{score || 0}
								</Typography>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default UserRankDetails
