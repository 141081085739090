export const sortEnum = {
	asc: 1,
	desc: -1,
}

export const pageVariants = {
	initial: {
		opacity: 0, // Start invisible
	},
	in: {
		opacity: 1, // Fade in to visible
	},
	out: {
		opacity: 0, // Fade out to invisible
	},
}

export const pageTransition = {
	type: 'tween', // Smoother, linear fade
	ease: 'easeInOut', // Smooth transition
	duration: 0.3, // Shorter duration for quicker fade
}

export const playlistCOnstants = {
	myPlaylist: 'My Playlists',
	friendsPlaylist: 'Friends Playlists',
}
