// Define the structure of the application configuration
interface AppConfig {
	environment: string
	theme: string
	localStorage: string
}
interface apiProps {
	baseUrl: string
	analyticsBaseUrl: string
	resourceBaseUrl: string
}

interface keysProps {
	resDecryptKey: string
	resDecryptIv: string
	reqEncryptKey: string
	reqEncryptIv: string
}

// Define the structure of the common configuration, which includes the application configuration
interface CommonConfig {
	app: AppConfig
	api: apiProps
	keys: keysProps
}

// Create an instance of the common configuration with default values
export const commonConfig: CommonConfig = {
	app: {
		// Set the environment value from the environment variables
		environment: String(process.env.REACT_APP_ENV),
		theme: String(process.env.REACT_APP_CURRENT_THEME),
		localStorage: String(process.env.REACT_APP_USERLOCALSTORAGE),
	},
	api: {
		baseUrl: String(process.env.REACT_APP_BASE_URL),
		analyticsBaseUrl: String(process.env.REACT_APP_ANALYTICS_BASE_URL),
		resourceBaseUrl: String(process.env.REACT_APP_RESOURCE_BASE_URL),
	},
	keys: {
		resDecryptKey: String(process.env.REACT_APP_RES_DEC_KEY),
		resDecryptIv: String(process.env.REACT_APP_RES_DEC_IV),
		reqEncryptKey: String(process.env.REACT_APP_REQ_ENCRYPT_KEY),
		reqEncryptIv: String(process.env.REACT_APP_REQ_ENCRYPT_IV),
	},
}
