import React, { createContext, useState, useContext, ReactNode } from 'react'

interface NavbarContextType {
	isNavbarVisible: boolean
	setNavbarVisible: (visible: boolean) => void
}

const NavbarContext = createContext<NavbarContextType | undefined>(undefined)

interface NavbarProviderProps {
	children: ReactNode
}

export const NavbarProvider: React.FC<NavbarProviderProps> = ({ children }) => {
	const [isNavbarVisible, setNavbarVisible] = useState(true)

	return <NavbarContext.Provider value={{ isNavbarVisible, setNavbarVisible }}>{children}</NavbarContext.Provider>
}

export const useNavbar = (): NavbarContextType => {
	const context = useContext(NavbarContext)
	if (context === undefined) {
		throw new Error('useNavbar must be used within a NavbarProvider')
	}
	return context
}
