import React, { useEffect, useState } from 'react'
import { Box, Typography, Tooltip, styled, useMediaQuery, useTheme } from '@mui/material'
import CustomIcon from '../../custom_components/CustomIcon'
import { iconKeys, typographyKeys } from '../../resources/resourceConstants'
import { useLocalize } from '../../custom_components/CustomHooks'
import { localeKeys } from '../../resources/localeConstants'
import ZerothScreen from '../../custom_components/ZerothScreen'
import { flexStyles } from '../../app_features/flexStyles'
import { getUserFromLocalStorage } from '../../utils/localStorage'
import { UserProfile } from '../home_thunk/homeDatamodal'

// Styled Components
const LeaderboardContainer = styled(Box)(() => ({
	position: 'relative',
	width: '100%',
	maxWidth: '1200px',
	margin: '0 auto',
	padding: '32px 10px',
	background: 'transparent',
	minHeight: '300px',
	overflow: 'visible',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
}))

// Define a props interface to specify the 'rank' prop
interface ShadowBackgroundProps {
	rank: number
}

const ShadowBackground = styled(Box)<ShadowBackgroundProps>(({ rank }) => ({
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -70%) scale(1.5)', // Center and scale the shadow
	width: '60%', // Match the avatar or container size
	height: '40%',
	borderRadius: '50%', // Ensure a circular glow
	zIndex: -1,
	boxShadow: `0 0 10px 10px ${
		rank === 1
			? 'rgba(255, 215, 0, 0.3)' // Gold shadow for rank 1
			: rank === 2
			? 'rgba(192, 192, 192, 0.3)' // Silver shadow for rank 2
			: 'rgba(205, 127, 50, 0.3)' // Bronze shadow for rank 3
	}`,
}))

const FloatingContainer = styled(Box)(() => ({
	position: 'relative',
	animation: 'float 3s ease-in-out infinite',
	'@keyframes float': {
		'0%, 100%': {
			transform: 'translateY(0)',
		},
		'50%': {
			transform: 'translateY(-10px)',
		},
	},
}))

const HexagonAvatar = styled(Box)(() => ({
	width: '52px',
	height: '52px',
	position: 'relative',
	'&::before': {
		content: '""',
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		background: 'linear-gradient(45deg, #1a1a3a, #2a2a4a)',
		clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)',
		border: '2px solid rgba(255,255,255,0.1)',
	},
	'& img': {
		width: '90%',
		height: '90%',
		objectFit: 'cover',
		position: 'absolute',
		top: '5%',
		left: '5%',
		clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)',
	},
}))

const PlayerContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	gap: '8px',
	textAlign: 'center',
	position: 'relative',
	paddingTop: '20px',
	width: '170',

	[theme.breakpoints.down('ml')]: {
		width: '160px',
	},
	[theme.breakpoints.down('lg')]: {
		width: '130px',
	},
	[theme.breakpoints.down('md')]: {
		width: '110px',
	},
	[theme.breakpoints.down('sm')]: {
		width: '90px',
	},
}))

interface LeaderboardComponentProps {
	players: {
		id: string
		name: string
		score: number
		rank: number
		avatar: string
		details: { gender: string; country: string; state: string; city: string }
	}[]
	currentPage: number
}

// LeaderboardComponent Function
export const LeaderboardComponent = (props: LeaderboardComponentProps) => {
	const { players, currentPage } = props
	const theme = useTheme()
	const localize = useLocalize()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const isTablet = useMediaQuery('(max-width: 769px)')

	const [top3players, setTop3Players] = useState<LeaderboardComponentProps['players']>([])
	const [allTimeTop3, setAllTimeTop3] = useState<LeaderboardComponentProps['players']>([])
	const userData = getUserFromLocalStorage() as UserProfile

	const rankToIconKey: { [key: number]: string } = {
		1: iconKeys.crownRankOne,
		2: iconKeys.crownRankTwo,
		3: iconKeys.crownRankThree,
	}

	useEffect(() => {
		if (players.length > 0) {
			// Sort players by score in descending order
			const sortedPlayers = [...players].sort((a, b) => b?.score - a?.score)

			// Assign ranks based on score
			let currentRank = 1
			let prevScore = sortedPlayers[0]?.score
			sortedPlayers.forEach((player, index) => {
				if (player?.score < prevScore) {
					currentRank = index + 1
				}
				player.rank = currentRank
				prevScore = player?.score
			})

			// Get the top 3 scores
			const topScores = Array.from(new Set(sortedPlayers.slice(0, 3).map((p) => p?.score)))

			// Filter players with top 3 scores
			let topPlayers = sortedPlayers.filter((p) => topScores.includes(p?.score))

			// If the current user is in the top players, move them to the front
			const userIndex = topPlayers.findIndex((p) => p.id === userData._id)
			if (userIndex !== -1) {
				const userPlayer = topPlayers.splice(userIndex, 1)[0]
				topPlayers.unshift(userPlayer)
			} else {
				// If user is not in top players but has a rank of 3 or better, add them
				const userPlayer = sortedPlayers.find((p) => p.id === userData._id)
				if (userPlayer && userPlayer.rank <= 3) {
					topPlayers.push(userPlayer)
				}
			}

			// Limit to 3 players
			topPlayers = topPlayers.slice(0, 3)

			// Assign display ranks
			topPlayers = topPlayers.map((player, index) => ({
				...player,
				displayRank: index + 1,
			}))

			if (currentPage === 1) {
				setTop3Players(topPlayers)
				setAllTimeTop3(topPlayers)
			} else {
				setTop3Players(allTimeTop3)
			}
		} else {
			setTop3Players([])
		}
	}, [players, currentPage, userData._id])

	return (
		<LeaderboardContainer>
			<Box display='flex' justifyContent='center' alignItems='flex-end' sx={{ gap: '4px' }}>
				{top3players.length > 0 ? (
					top3players.map((player, index) => {
						const isAllSameScore = top3players.every((p) => p?.score === top3players[0]?.score)
						let order = player.rank === 1 ? 2 : player.rank === 2 ? 1 : 3
						let topOffset = '0px'

						if (isAllSameScore) {
							if (player.id === userData._id) {
								order = 2 // Current user always in the middle
								topOffset = '0px'
							} else if (index === 0) {
								order = 1 // First non-user player on the left
								topOffset = '0px'
							} else {
								order = 3 // Other player on the right
								topOffset = '0px'
							}
						} else if (index === 0 && player?.score === top3players[1]?.score) {
							// Top 2 players have the same score
							topOffset = '-20px'
						} else if (index === 1 && player?.score === top3players[0]?.score) {
							// 2nd player has the same score as 1st
							topOffset = '-20px'
						} else if (index === 1 && player?.score === top3players[2]?.score) {
							// 2nd and 3rd players have the same score
							topOffset = '0px'
							order = 1
						} else if (index === 2 && player?.score === top3players[1]?.score) {
							// 3rd player has the same score as 2nd
							topOffset = '0px'
							order = 3
						} else {
							// Original logic for different scores
							topOffset = player?.rank === 1 ? '-20px' : player?.rank === 2 ? '0px' : '10px'
						}

						return (
							<PlayerContainer key={player.id} sx={{ order, top: topOffset }}>
								<FloatingContainer>
									<ShadowBackground rank={player.rank} />

									{/* Background icon with lowest zIndex */}
									<Box
										sx={{
											position: 'relative',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											zIndex: 0,
										}}
									>
										{/* Background Flower Icon */}
										<CustomIcon
											name={
												player.rank === 1
													? iconKeys.flowersRankOne
													: player.rank === 2
													? iconKeys.flowersRankTwo
													: iconKeys.flowersRankThree
											}
											style={{
												position: 'absolute',
												top: '20px',
												left: '50%',
												transform: 'translate(-50%, -50%)',
												zIndex: 0,
											}}
										/>
										{/* Rank Icon Positioned Above the Flower Icon */}
										<Box
											sx={{
												position: 'absolute',
												top: '-20px',
												left: '50%',
												transform: 'translateX(-50%)',
												zIndex: 2,
											}}
										>
											<CustomIcon name={rankToIconKey[player.rank]} />
										</Box>

										{/* Hexagon Avatar Positioned Above Everything */}
										<HexagonAvatar sx={{ zIndex: 3 }}>
											<img src={player.avatar} alt={player.name} />
										</HexagonAvatar>
									</Box>
								</FloatingContainer>

								<Box display='flex' alignItems='center' gap={1}>
									<Typography
										variant={typographyKeys.h4}
										sx={{
											color: 'text.primary',
											fontSize: isTablet ? 'clamp(2.7vw, 2vw, 3vw)' : 'clamp(1.2vw, 1vw, 1vw)',
										}}
									>
										{player.name === userData.displayName ? 'You' : player.name}
									</Typography>
									<Tooltip
										title={
											<Box sx={{ ...flexStyles.flexColumn, gap: '5px', padding: '5px' }}>
												<Box display='flex'>
													<Typography variant={typographyKeys.label2} sx={{ color: 'background.backgroundMain' }}>
														{localize(localeKeys.gender)}:
													</Typography>
													<Typography
														variant={typographyKeys.label3}
														sx={{ marginLeft: 1, color: 'background.backgroundMain' }}
													>
														{player.details.gender || 'NA'}
													</Typography>
												</Box>
												<Box display='flex'>
													<Typography variant={typographyKeys.label2} sx={{ color: 'background.backgroundMain' }}>
														{localize(localeKeys.country)}:{' '}
													</Typography>
													<Typography
														variant={typographyKeys.label3}
														sx={{ marginLeft: 1, color: 'background.backgroundMain' }}
													>
														{player.details.country || 'NA'}
													</Typography>
												</Box>
												<Box display='flex'>
													<Typography variant={typographyKeys.label2} sx={{ color: 'background.backgroundMain' }}>
														{localize(localeKeys.state)}:{' '}
													</Typography>
													<Typography
														variant={typographyKeys.label3}
														sx={{ marginLeft: 1, color: 'background.backgroundMain' }}
													>
														{player.details.state || 'NA'}
													</Typography>
												</Box>
												<Box display='flex'>
													<Typography variant={typographyKeys.label2} sx={{ color: 'background.backgroundMain' }}>
														{localize(localeKeys.city)}:{' '}
													</Typography>
													<Typography
														variant={typographyKeys.label3}
														sx={{ marginLeft: 1, color: 'background.backgroundMain' }}
													>
														{player.details.city || 'NA'}
													</Typography>
												</Box>
											</Box>
										}
										arrow
										componentsProps={{
											tooltip: {
												sx: {
													backgroundColor: 'background.surface',
													color: 'background.backgroundMain',
													border: '1px solid',
													borderColor: 'border.lightBorder',
													borderRadius: '6px',
												},
											},
											arrow: {
												sx: {
													color: 'background.surface',
													'&::before': {
														border: '1px solid',
														borderColor: 'border.lightBorder',
													},
												},
											},
										}}
										enterTouchDelay={0}
									>
										<Box sx={{ display: 'inline-flex', cursor: 'pointer' }}>
											{' '}
											{/* Ensures hover effect on custom icon */}
											<CustomIcon
												name={iconKeys.infoCircle}
												style={{ width: '18px', height: '18px' }}
												svgStyle={isMobile ? 'width:12px;height:12px' : 'width:18px;height:18px'}
											/>
										</Box>
									</Tooltip>
								</Box>

								<Box display='flex' alignItems='center' gap={1}>
									<Typography sx={{ color: 'text.success' }} variant={typographyKeys.h3}>
										{player?.score}
									</Typography>
								</Box>

								<CustomIcon
									name={
										isAllSameScore || (index <= 1 && player?.score === top3players[0]?.score)
											? iconKeys.rankBoxOne
											: index >= 1 && player?.score === top3players[1]?.score
											? iconKeys.rankBoxTwo
											: iconKeys[`rankBox${player.rank === 1 ? 'One' : player.rank === 2 ? 'Two' : 'Three'}`]
									}
									svgStyle={`width:100%; height:100%`}
								/>
							</PlayerContainer>
						)
					})
				) : (
					<ZerothScreen
						icon={iconKeys.leaderBoardZeroth2}
						imageWidth={'100px'}
						imageHeight={'100px'}
						tilte={localeKeys.noRanksAt}
						subTitle={localeKeys.startPlayingNowToEarn}
					/>
				)}
			</Box>
		</LeaderboardContainer>
	)
}
