import { Box, Collapse, Typography } from '@mui/material'
import React, { ReactElement, useState } from 'react'
import { flexStyles } from '../app_features/flexStyles'
import CustomIcon from './CustomIcon'
import { iconKeys, typographyKeys } from '../resources/resourceConstants'
import { useLocalize } from './CustomHooks'
import CustomSwitch from './CustomSwitch'
import { localeKeys } from '../resources/localeConstants'
import CookieTable from './CookieTable'
import { ToggleState } from './CookiesDialog'

export interface CollapseUIData {
	title: string
}

export interface CookieData {
	cookieName: string
	cookieType: string
	category: string
	expirationDuration: string
	platformName: string
	description: string
}

export interface CollapseCookieUIProps {
	descriptionUI: ReactElement
	data: CollapseUIData
	isChecked: boolean
	handleChange: (checked: boolean, key: string) => void
	itemKey: string
	disableCheck?: boolean
	cookieData: CookieData[]
	collapse: boolean
	handleCollapseChange: (key: keyof ToggleState) => void
}

const CollapseCookieUI = ({
	descriptionUI,
	data,
	isChecked,
	itemKey,
	handleChange,
	disableCheck,
	cookieData,
	collapse,
	handleCollapseChange,
}: CollapseCookieUIProps) => {
	const [viewCookieList, setviewCookieList] = useState(false)
	const localize = useLocalize()

	return (
		<Box>
			<Box
				sx={{
					...flexStyles.flexRowSpaceBetweenAlignCenter,
					gap: '8px',
					p: '8px 19px',
					border: '1px solid',
					borderColor: 'border.defaultBorder',
					borderRadius: '10px',
					width: '100%',
					backgroundColor: 'transparent',
					cursor: 'pointer',
				}}
				onClick={() => handleCollapseChange(itemKey as keyof ToggleState)}
				component='button'
			>
				<Box sx={{ ...flexStyles.flexCenter, gap: '2px' }}>
					<CustomIcon
						name={collapse ? iconKeys.collapse : iconKeys.expand}
						style={{ height: '28px', width: '28px' }}
						svgStyle='height: 28px; width: 28px'
					/>

					<Typography variant={typographyKeys.h4} sx={{ color: 'text.defaultHeading' }}>
						{localize(data.title)}
					</Typography>
				</Box>
				<Box sx={{ ...flexStyles.flexCenter, gap: '16px' }}>
					<Typography variant={typographyKeys.button1} sx={{ color: 'text.defaultHeading' }}>
						{' '}
						{isChecked ? 'Enabled' : 'Enable'}
					</Typography>
					<CustomSwitch
						checked={isChecked}
						onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleChange(checked, itemKey)}
						disable={disableCheck}
					/>
				</Box>
			</Box>
			<Collapse in={collapse} timeout='auto'>
				<Box sx={{ ...flexStyles.flexColumn, p: '20px 20px 5px 20px', gap: '20px' }}>
					{descriptionUI}

					<Typography
						variant={typographyKeys.subtitle1}
						sx={{ color: 'text.subHeading', textDecoration: 'underline', cursor: 'pointer' }}
						onClick={() => setviewCookieList(!viewCookieList)}
					>
						<strong> {localize(localeKeys.cookieList)}</strong>
					</Typography>

					<Collapse in={viewCookieList} timeout='auto'>
						<Box sx={{ ...flexStyles.flexColumn, gap: '20px' }}>
							{cookieData.map((cookie, index) => {
								return <CookieTable cookie={cookie} key={index} />
							})}
						</Box>
					</Collapse>
				</Box>
			</Collapse>
		</Box>
	)
}

export default CollapseCookieUI
