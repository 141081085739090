import { apiEndPoints, apiHeaders } from '../../utils/apiConstants'
import customFetch from '../../utils/customAxios'
import { AnyObject } from '../../utils/utils'
import { s3UploadThunkProps } from './currentUserSlice'

/**
 * Asynchronous thunk for logging out the current user.
 *
 * @function
 * @async
 * @returns {Promise<{ data: any } | Error>} A Promise that resolves to the response data or an Error if the request fails.
 * @throws {Error} If the logout request encounters an error.
 */
export const logoutThunk = async (_: AnyObject, thunkApi: AnyObject) => {
	try {
		const response = await customFetch.put(apiEndPoints.logout, {}, { withCredentials: true })
		thunkApi.dispatch({ type: 'logout' })
		return { data: response.data }
	} catch (error) {
		return error
	}
}

export const getMiscellaneousThunk = async () => {
	const response = await customFetch.get(apiEndPoints.fetchMiscellaneous, {
		headers: {
			[apiHeaders.preventDefaultLoader]: true,
		},
	})

	// Return the response data with the specified type
	return { data: response.data }
}

export const uploadImageToS3BucketThunk = async ({ s3Link, file, contentType }: s3UploadThunkProps, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.put('', file, {
			baseURL: s3Link,
			headers: {
				[apiHeaders.contentType]: contentType,
				[apiHeaders.uploadToS3]: true,
			},
		})

		return { data: response.data }
	} catch (error) {
		return rejectWithValue({
			status: 'rejected',
			message: 'error uploading file',
		})
	}
}

interface UploadProfilePicProps {
	body: {
		profilePicture: string
	}
	isUploaded: boolean
}

export const uploadProfilePicThunk = async ({ body, isUploaded }: UploadProfilePicProps, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.put(apiEndPoints.profilePicUpdate + isUploaded, body)
		return { data: response.data }
	} catch (error) {
		return rejectWithValue(error)
	}
}
