import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchAdherenceThunk, viewDashboardThunk } from './thunk'
import { AdherenceRes, DashboardData } from './dataModal'

interface DashboardState {
	dashboardData: DashboardData | null
	adherenceData: AdherenceRes | null
}

const initialState: DashboardState = {
	dashboardData: null,
	// adherenceData: null,
	adherenceData: {
		'2024-11-23': { rankingConsistencyPoints: 12, scoreImprovementPoints: 8 },
		'2024-11-22': { rankingConsistencyPoints: 1, scoreImprovementPoints: 10 },
		'2024-11-21': { rankingConsistencyPoints: 0, scoreImprovementPoints: 0 },
		'2024-11-20': { rankingConsistencyPoints: 8, scoreImprovementPoints: 6 },
		'2024-11-19': { rankingConsistencyPoints: 10, scoreImprovementPoints: 9 },
		'2024-11-18': { rankingConsistencyPoints: 10, scoreImprovementPoints: 10 },

		'2024-11-16': { rankingConsistencyPoints: 10, scoreImprovementPoints: 10 },
		'2024-11-15': { rankingConsistencyPoints: 10, scoreImprovementPoints: 10 },

		'2024-10-15': { rankingConsistencyPoints: 10, scoreImprovementPoints: 10 },
		'2024-10-14': { rankingConsistencyPoints: 10, scoreImprovementPoints: 10 },
		'2024-8-14': { rankingConsistencyPoints: 10, scoreImprovementPoints: 10 },
	},
}

export const viewDashboardApi = createAsyncThunk('dashboard/viewDashboard', viewDashboardThunk)
export const fetchAdherenceApi = createAsyncThunk('dashboard/adherence', fetchAdherenceThunk)

// Slice with reducers and extraReducers for handling async actions
const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(viewDashboardApi.fulfilled, (state, action) => {
				// state.error = null
				state.dashboardData = action.payload.data.data
			})
			.addCase(fetchAdherenceApi.fulfilled, (state, action) => {
				// state.error = null
				state.adherenceData = action.payload.data.data
			})
	},
})

export default dashboardSlice.reducer
