import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { changeRoute } from '../../utils/utils'
import { pathKeys } from '../../routes/routeKeys'
import { getMiscellaneousThunk, logoutThunk, uploadImageToS3BucketThunk, uploadProfilePicThunk } from './currentUserThunk'

export interface s3UploadThunkProps {
	s3Link: string
	file: File
	contentType: string
}
export interface MiscellaneousRes {
	quickPicks: Record<string, string>
}
// slice data structure
export interface CurrentUserProps {
	isLoading: boolean
	hideLoader: boolean
	showToast: boolean
	title: string
	subTitle: string
	isSuccess: boolean
	anchorOrigin: { vertical: 'top' | 'bottom'; horizontal: 'right' | 'left' | 'center' }
	profileData: string
	isFetchingData: boolean
	isRefreshing: boolean
	miscellaneous: MiscellaneousRes | null
	isSocialAuth: boolean
}

//intial state
const initialState: CurrentUserProps = {
	isLoading: false,
	hideLoader: false,
	showToast: false,
	title: '',
	subTitle: '',
	isSuccess: true,
	anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
	profileData: '',
	isFetchingData: false,
	isRefreshing: false,
	miscellaneous: null,
	isSocialAuth: false,
}

// async thunks for profile api calls
export const logoutApi = createAsyncThunk('currentUser/logout', logoutThunk)
export const getMiscellaneous = createAsyncThunk('currentUser/getMiscellaneous', getMiscellaneousThunk)
export const uploadImageToS3 = createAsyncThunk('currentUser/uploadS3', uploadImageToS3BucketThunk)
export const uploadProfilePic = createAsyncThunk('currentUser/uploadProfilePic', uploadProfilePicThunk)

//slice for currently logged in user
const currentUserSlice = createSlice({
	name: 'currentUser',
	initialState,
	reducers: {
		logoutUser: () => {
			changeRoute({
				to: pathKeys.login,
			})
		},

		setHideLoader: (state, { payload }) => {
			state.hideLoader = payload
		},

		setIsRefreshing: (state, { payload }) => {
			state.isRefreshing = payload
		},

		updateIsLoading: (state, { payload }) => {
			state.isLoading = payload
		},

		updateIsSocialAuth: (state, { payload }) => {
			state.isSocialAuth = payload
		},

		updateShowToast: (state, { payload }) => {
			state.showToast = payload
		},
		updateToastData: (state, { payload }) => {
			state.title = payload.title
			state.subTitle = payload.subTitle
			state.isSuccess = payload.isSuccess
			state.anchorOrigin = payload.anchorOrigin
			state.showToast = payload.showToast
		},
		setIsFetchingData: (state, { payload }) => {
			state.isFetchingData = payload
		},
		clearCurrentUser: () => initialState,
	},

	extraReducers: (builder) => {
		// Handle the fulfilled case of the login API call
		builder
			.addCase(logoutApi.fulfilled, (state) => {
				state.profileData = ''
			})
			.addCase(logoutApi.rejected, (state) => {
				state.profileData = ''
			})
			.addCase(getMiscellaneous.fulfilled, (state, action) => {
				state.miscellaneous = action.payload.data.data
			})
	},
})

export const {
	logoutUser,
	setHideLoader,
	updateIsLoading,
	updateShowToast,
	updateToastData,
	clearCurrentUser,
	setIsFetchingData,
	setIsRefreshing,
	updateIsSocialAuth,
} = currentUserSlice.actions
export default currentUserSlice.reducer
