'use client'

import React from 'react'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, useMediaQuery, useTheme } from '@mui/material'
import CustomIcon from './CustomIcon'
import { iconKeys } from '../resources/resourceConstants'
import { flexStyles, globalStyles } from '../app_features/flexStyles'

interface HtmlEmbedDialogProps {
	open: boolean
	setOpen: (open: boolean) => void
	url: string
}

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		gap: '12px',
	},
	dialogImage: {
		maxWidth: '100%',
		maxHeight: '100%',
	},
}

const HtmlEmbedDialog: React.FC<HtmlEmbedDialogProps> = ({ open, setOpen, url }) => {
	const theme = useTheme()
	const isBelow600 = useMediaQuery(theme.breakpoints.down(600))

	const handleCloseDialog = () => {
		setOpen(false)
	}

	return (
		<Box sx={styles.container}>
			<Dialog
				open={open}
				onClose={handleCloseDialog}
				fullWidth
				maxWidth='md'
				sx={{
					'& .MuiDialog-paper': {
						width: '100%',
						maxWidth: '900px',
						padding: isBelow600 ? '20px 0px' : '22px 22px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						borderRadius: '16px',
						backgroundColor: 'background.surface',
						backgroundImage: 'none',
					},
					backdropFilter: 'blur(3px)',
				}}
			>
				<DialogTitle sx={{ padding: isBelow600 ? '0px 10px 0px 20px' : '0px', width: '100%', ...flexStyles.flexCenterSpaceBetween }}>
					<CustomIcon
						name={iconKeys.getFitNowIcon}
						style={{ width: isBelow600 ? '100px' : '170px', height: isBelow600 ? '32px' : '60px' }}
						svgStyle={isBelow600 ? 'width:100px;height:32px' : 'width:170px;height:60px'}
					/>
					<IconButton
						aria-label='close'
						onClick={() => {
							setOpen(false)
						}}
						// sx={{ position: 'absolute', right: 8, top: 8 }}
					>
						<CustomIcon name={iconKeys.closeCircle} style={{ width: '24px', height: '24px' }} />
					</IconButton>
				</DialogTitle>
				<DialogContent sx={{ width: '100%', p: isBelow600 ? '' : '10px', ...globalStyles.hideScroll }}>
					<Box sx={{ textAlign: 'center', width: '100%' }}>
						<embed src={url} width='100%' height='600px' type='text/html' />
					</Box>
				</DialogContent>
			</Dialog>
		</Box>
	)
}

export default HtmlEmbedDialog
