export const pathKeys = {
	root: '/',
	allOther: '*',
	login: 'login',
	home: 'home',
	games: 'games',
	notFound: 'not-found',
}

export const routePaths = {
	home: '/home',
	features: '/features',
	howItWorks: '/howItWorks',
	aboutUs: '/about-us',
	games: '/games',
	dashboard: '/dashboard',
	singleGame: '/games/single-game',
	friends: '/friends',
	leaderboard: '/leaderboard',
}
