import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import { CookieData } from './CollapseCookieUI'
import { useLocalize } from './CustomHooks'
import { typographyKeys } from '../resources/resourceConstants'

const CookieTable = ({ cookie }: { cookie: CookieData }) => {
	const localize = useLocalize()

	// Create an array of key-value pairs for table data
	const rows = [
		{ label: 'Cookie Name', value: cookie.cookieName },
		{ label: 'Cookie Type', value: cookie.cookieType },
		{ label: 'Category', value: cookie.category },
		{ label: 'Expiration/Duration', value: cookie.expirationDuration },
		{ label: 'Platform Name', value: cookie.platformName },
		{ label: 'Description', value: cookie.description },
	]

	return (
		<TableContainer>
			<Table aria-label='cookie table'>
				<TableBody>
					{rows.map((row, index) => (
						<TableRow key={index}>
							<TableCell
								sx={{
									borderBottom: 'none',
									border: '1px solid',
									borderColor: 'border.defaultBorder',
									width: '160px', // Fixed width for the first column

									p: '10px 20px',
								}}
							>
								<Typography variant={typographyKeys.subtitle1} sx={{ color: 'text.defaultHeading' }}>
									{localize(row.label)}
								</Typography>
							</TableCell>
							<TableCell
								sx={{
									borderBottom: 'none',
									border: '1px solid',
									borderColor: 'border.defaultBorder',
									p: '10px 20px',
								}}
							>
								<Typography variant={typographyKeys.subtitle1} sx={{ color: 'text.defaultHeading' }}>
									{' '}
									{localize(row.value)}{' '}
								</Typography>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default CookieTable
