import React, { CSSProperties, ReactElement, useState } from 'react'
import { TableBody, TableRow, TableCell, Typography, Avatar, Box, Popover, IconButton, useTheme } from '@mui/material'
import CustomIcon from './CustomIcon'
import { iconKeys, typographyKeys } from '../resources/resourceConstants'
import { localeKeys } from '../resources/localeConstants'
import { flexStyles } from '../app_features/flexStyles'
import { EmptyFunction } from '../app_features/types'
import { TableColumnProps } from './CustomTableHeader'
import { useLocalize } from './CustomHooks'
import { commonStyles } from '../app_features/commonStyles'
import { logger } from '../logger'

// Enum defining various types of table cell content
export enum TableCellType {
	normal = 'normal',
	requestType = 'requestType',
	status = 'status',
	avatar = 'avatar',
	icon = 'icon',
	boldText = 'boldText',
	logo = 'logo',
	colorBox = 'colorBox',
	singleIcon = 'singleIcon',
	onlyIcon = 'onlyIcon',
	multipleIcon = 'multipleIcon',
	multipleTypo = 'multipleTypo',
	iconPopover = 'iconPopover',
	rank = 'rank',
	iconText = 'iconText',
}

// Props for configuring a table cell component
export type TableCellProps = {
	id: string
	value: string
	valueSubtitle?: string
	isMultiline: boolean
	type: TableCellType
	privateUrl: string
	iconName: string
	bgColor: string
	textColor: string
	boxColor: string
	logoCss: CSSProperties
	onClick?: EmptyFunction
	onClickIcon2?: EmptyFunction
	onClickIcon3?: EmptyFunction
	iconData?: {
		_id: string
		iconName: string
		width: string
		height: string
		onClick: (event: React.MouseEvent<unknown>, rowIndex: number, rowId: string) => void
	}[]
	handlePopoverEdit?: (id: string) => void
	handlePopoverDelete?: (id: string) => void
	disabled?: boolean
}

// Default data for a table cell
export const defaultTableCellData: TableCellProps = {
	id: '',
	value: 'NA',
	isMultiline: true,
	type: TableCellType.normal,
	privateUrl: '',
	iconName: '',
	bgColor: 'none',
	textColor: 'text.secondary',
	boxColor: 'none',
	logoCss: { height: '33px', width: '42px', borderRadius: '4px' },
}

// Props for configuring a table row
export type TableRowProps = {
	index: number
	_id: string
	data: TableCellProps[]
}

// Props for configuring the body of a table component
export interface TableBodyProps {
	rows: TableRowProps[]
	columns: TableColumnProps[]
	handleRowClick?: (event: React.MouseEvent<unknown>, rowIndex: number, rowId: string) => void
	selectedRowIds?: string[]
	isCheckboxNeeded?: boolean
	handleIconClick?: (event: React.MouseEvent<unknown>, rowIndex: number) => void
	selectedRowId?: string
	userId?: string
}

const CustomTableBody = ({ ...props }: TableBodyProps): ReactElement => {
	const { rows, columns, handleRowClick, selectedRowIds, isCheckboxNeeded = false, handleIconClick, userId } = props
	const localize = useLocalize()
	const theme = useTheme()

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const [popoverRowId, setPopoverRowId] = useState<string | null>(null)
	const [hoveredRowId, setHoveredRowId] = useState<string | null>(null)
	const [hoveredRowIndex, setHoveredRowIndex] = useState<string | null>(null)
	logger.log('hoveredRowId', hoveredRowId)

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement | SVGElement>, rowId: string) => {
		setAnchorEl(event.currentTarget as HTMLElement)
		setPopoverRowId(rowId)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
	}

	const RowCells = (cell: TableCellProps | undefined, rowIndex: number, rowId: string) => {
		if (cell) {
			const isHovered = hoveredRowIndex === rowIndex.toString()
			const isUserRow = rowId === userId
			const textColor = isUserRow ? 'common.black' : isHovered ? 'common.black' : cell.textColor ?? 'text.secondary'

			switch (cell.type) {
				case TableCellType.normal:
					return (
						<Typography
							variant={typographyKeys.subtitle1}
							sx={{
								...commonStyles.typographyTextNextLine,
								display: '-webkit-box',
								WebkitLineClamp: 2,
								WebkitBoxOrient: 'vertical',
								overflow: 'hidden',
								color: textColor,
							}}
						>
							{cell.value || localize(localeKeys.notApplicable)}
						</Typography>
					)
				case TableCellType.avatar:
					return (
						<Box sx={{ ...flexStyles.flexRowAlignCenter }}>
							{cell.privateUrl ? (
								<Avatar
									src={cell.privateUrl}
									sx={{
										height: '24px',
										width: '24px',
										clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)',
										border: '1px solid',
										borderColor: 'border.active',
									}}
								/>
							) : (
								<Box
									sx={{
										width: '24px',
										height: '24px',
										backgroundColor: 'border.disable',
										clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)',
										...flexStyles.flexRowCenter,
									}}
								>
									{cell?.value[0]?.toUpperCase()}
								</Box>
							)}
							{cell.value !== '' ? (
								<Typography
									variant={typographyKeys.subtitle1}
									sx={{ ...commonStyles.typographyTextNextLine, ml: '10px', color: textColor }}
								>
									{cell.value}
								</Typography>
							) : null}
						</Box>
					)
				case TableCellType.iconText:
					return (
						<Box gap={'10px'} sx={{ ...flexStyles.flexRowAlignCenter }}>
							{cell.value ? (
								<CustomIcon
									onClick={(event) => handleIconClick?.(event, rowIndex) ?? null}
									name={cell.iconName}
									style={{ width: '16px', height: '16px' }}
								/>
							) : null}
							<Typography variant={typographyKeys.button} sx={{ ...commonStyles.typographyTextNextLine, color: 'text.success' }}>
								{cell.value}
							</Typography>
						</Box>
					)
				case TableCellType.rank:
					return (
						<Box sx={{ ...flexStyles.flexRow }} gap={'4px'}>
							<CustomIcon
								onClick={(event) => handleIconClick?.(event, rowIndex) ?? null}
								name={cell.iconName}
								style={{ width: Number(cell.value) > 3 ? '16px' : '27px', height: Number(cell.value) > 3 ? '16px' : '20px' }}
								// svgStyle={`width: ${Number(cell.value) > 3 ? '16px' : '27px'}; width: ${Number(cell.value) > 3 ? '16px' : '20px'}`}
							/>
							{Number(cell.value) > 3 && (
								<Typography variant={typographyKeys.h5} sx={{ ...commonStyles.typographyTextNextLine, color: textColor }}>
									{cell.value}
								</Typography>
							)}
						</Box>
					)
				case TableCellType.multipleIcon:
					return (
						<Box sx={{ ...flexStyles.flexRowAlignCenter, gap: '5px' }}>
							{cell?.iconData?.map((iconData, iconIndex) => (
								<React.Fragment key={iconIndex}>
									<IconButton style={{ width: iconData.width, height: iconData.height }}>
										<CustomIcon
											onClick={(event) => iconData.onClick(event, rowIndex, iconData._id)}
											name={iconData.iconName}
											style={{ width: iconData.width, height: iconData.height }}
										/>
									</IconButton>
								</React.Fragment>
							))}
						</Box>
					)
				case TableCellType.boldText:
					return (
						<Typography variant={typographyKeys.body1} sx={{ ...commonStyles.typographyTextNextLine, color: textColor }}>
							{cell.value || localize(localeKeys.notApplicable)}
						</Typography>
					)
				case TableCellType.logo:
					return (
						<Box sx={{ ...flexStyles.flexRowCenter }}>
							<Avatar
								src={cell.privateUrl}
								variant='square'
								sx={{
									...cell.logoCss,
									mr: '25px',
								}}
							/>
						</Box>
					)
				case TableCellType.colorBox:
					return (
						<Box sx={{ ...flexStyles.flexRowJustifyStart, pt: '6px' }}>
							<Box sx={{ backgroundColor: cell.boxColor ?? 'red', height: '24px', width: '24px', borderRadius: '5px' }} />
							<Typography variant={typographyKeys.body2} sx={{ ...commonStyles.typographyTextNextLine, ml: '10px', color: textColor }}>
								{cell.value || localize(localeKeys.notApplicable)}
							</Typography>
						</Box>
					)
				case TableCellType.onlyIcon:
					return (
						<Box sx={{ ...flexStyles.flexRowCenter }}>
							<Typography variant={typographyKeys.body2} sx={{ ...commonStyles.typographyTextNextLine, color: textColor }}>
								{!cell?.onClick && localize(localeKeys.notApplicable)}
							</Typography>
							{cell?.onClick ? (
								<IconButton
									onClick={(event) => {
										event.preventDefault()
										event.stopPropagation()
										cell?.onClick?.()
									}}
								>
									<CustomIcon name={cell.iconName} style={{ width: '18px', height: '18px' }} />
								</IconButton>
							) : null}
						</Box>
					)
				case TableCellType.multipleTypo:
					return (
						<Box sx={{ ...flexStyles.flexColumnJustifyCenterAlignStart }}>
							<Typography variant={typographyKeys.body2} sx={{ ...commonStyles.typographyTextNextLine, ml: '10px', color: textColor }}>
								{cell.value || localize(localeKeys.notApplicable)}
							</Typography>
							<Typography variant={typographyKeys.label2} sx={{ ...commonStyles.typographyTextNextLine, ml: '10px', color: textColor }}>
								{cell.valueSubtitle || localize(localeKeys.notApplicable)}
							</Typography>
						</Box>
					)
				case TableCellType.iconPopover:
					return (
						<>
							<CustomIcon
								onClick={(event) => {
									event.preventDefault()
									event.stopPropagation()
									if (!cell.disabled) {
										handlePopoverOpen(event, rowId)
									}
								}}
								name={cell.iconName}
								style={{ width: '25px', height: '25px', marginRight: '' }}
							/>
							<Popover
								open={Boolean(anchorEl)}
								anchorEl={anchorEl}
								onClose={handlePopoverClose}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								slotProps={{
									paper: {
										sx: {
											boxShadow: 'none',
											border: '1px solid',
											borderColor: 'orange.lightmain',
										},
									},
								}}
							>
								<Box sx={{ p: '20px 17px', ...flexStyles.flexColumn, gap: '6px' }}>
									<Box
										sx={{
											...flexStyles.flexRow,
											gap: '10px',
											cursor: 'pointer',
											'&:hover': { backgroundColor: 'orange.lightmain' },
											p: '4px',
										}}
										onClick={() => {
											handlePopoverClose()
											cell?.handlePopoverEdit?.(popoverRowId ?? '')
										}}
									>
										<CustomIcon name={iconKeys.closeCircle} style={{ width: '20px', height: '20px' }} />
										<Typography variant={typographyKeys.body2}>{localize(localeKeys.edit)}</Typography>
									</Box>
									<Box
										sx={{
											...flexStyles.flexRow,
											gap: '10px',
											cursor: 'pointer',
											'&:hover': { backgroundColor: 'orange.lightmain' },
											p: '4px',
										}}
										onClick={() => {
											handlePopoverClose()
											cell?.handlePopoverDelete?.(popoverRowId ?? '')
										}}
									>
										<CustomIcon name={iconKeys.closeCircle} style={{ width: '20px', height: '20px' }} />
										<Typography variant={typographyKeys.body2}>{localize(localeKeys.delete)}</Typography>
									</Box>
								</Box>
							</Popover>
						</>
					)
				default:
					return null
			}
		}
		return null
	}

	return (
		<TableBody sx={{ ...commonStyles.commonTableBodyStyle }}>
			{rows?.length > 0 &&
				rows?.map((row: TableRowProps, index) => (
					<TableRow
						hover
						onClick={(event) => {
							handleRowClick?.(event, row.index, row._id)
						}}
						onMouseEnter={() => {
							setHoveredRowId(row._id)
							setHoveredRowIndex(index.toString())
						}}
						onMouseLeave={() => {
							setHoveredRowId(null)
							setHoveredRowIndex(null)
						}}
						tabIndex={-1}
						key={row.index}
						sx={{
							...commonStyles.commonTableRowStyle,
							background:
								row._id === userId
									? `linear-gradient(90deg, ${theme.palette.gradient.yellowish1} 0%, ${theme.palette.gradient.yellowish2} 100%)`
									: row.index % 2 === 0
									? theme.palette.background.default
									: theme.palette.background.surface,
							// backgroundColor: row._id !== userId ? 'background.surface' : 'inherit',
							'&:hover': {
								backgroundColor: '#f7fee7 !important',
							},
						}}
					>
						{isCheckboxNeeded && (
							<TableCell
								align='center'
								sx={{
									padding: '20px',
									minWidth: '20px',
									maxWidth: '20px',
								}}
							>
								<Box sx={{ ...flexStyles.flexRowCenter }}>
									<CustomIcon name={selectedRowIds?.includes(row._id) ? iconKeys.closeCircle : iconKeys.closeCircle} />
								</Box>
							</TableCell>
						)}
						{columns.map((column: TableColumnProps) => {
							const bgcolor = row.data.find((obj) => obj.id === column.id)?.bgColor ?? 'none'
							return (
								<TableCell
									key={column.id}
									align={column.align}
									sx={{
										// height: '73px',
										height: '10px',
										// padding: '10px',
										width: column.width,
										maxWidth: column.width,
										backgroundColor: column.position === 'sticky' && bgcolor === 'none' ? 'common.white' : bgcolor,
										...column.tableCellCss,
										position: column.position ?? 'none',
										zIndex: column.position === 'sticky' ? 99 : 0,
										top: column.position === 'sticky' ? 0 : 'none',
										left: column.position === 'sticky' ? 0 : 'none',
										border: 'none',
									}}
								>
									{RowCells(
										row.data.find((obj) => obj.id === column.id),
										row.index,
										row._id
									)}
								</TableCell>
							)
						})}
					</TableRow>
				))}
		</TableBody>
	)
}

export default CustomTableBody
