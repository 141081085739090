import { Suspense, useEffect, useState } from 'react'
import { CssBaseline } from '@mui/material'
import Loader from './custom_components/Loader'
import ErrorBoundary from './custom_components/ErrorBoundary'
import { useAppSelector } from './custom_components/CustomHooks'
import { useLocation, useNavigate } from 'react-router'
import { history } from './utils/utils'
import Toast from './custom_components/Toast'
import combinedRoutes from './routes/Routing'
import { NavbarProvider } from './mutiple-auth/NavbarContext'
import CookiesDrawer from './custom_components/CookiesDrawer'

function App() {
	const { hideLoader, isLoading, showToast, title, subTitle, isSuccess } = useAppSelector((state) => state.currentUser)
	const [viewToast, setViewToast] = useState(false)
	history.navigate = useNavigate()
	history.location = useLocation()

	useEffect(() => {
		setViewToast(showToast)
	}, [showToast])

	// Disable React Developer Tools in production
	useEffect(() => {
		if (process.env.REACT_APP_ENV === 'production') {
			if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
				for (const [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value === 'function' ? () => {} : null
				}
			}
		}
	}, [])

	return (
		<>
			<CssBaseline />
			<ErrorBoundary>
				<NavbarProvider>
					{viewToast && <Toast title={title} subTitle={subTitle} isSuccess={isSuccess} />}
					{isLoading && !hideLoader && <Loader />}
					<Suspense fallback={<Loader />}>{combinedRoutes}</Suspense>
					<CookiesDrawer />
				</NavbarProvider>
			</ErrorBoundary>
		</>
	)
}

export default App
