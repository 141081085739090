import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import { flexStyles, globalStyles } from '../app_features/flexStyles'
import CustomizedButton from './CustomButton'
import { useLocalize } from './CustomHooks'
import { localeKeys } from '../resources/localeConstants'
import { iconKeys, typographyKeys } from '../resources/resourceConstants'
import { EmptyFunction } from '../app_features/types'
import React, { useEffect, useState } from 'react'
import CustomIcon from './CustomIcon'
import CollapseCookieUI from './CollapseCookieUI'

export interface CookiesDialogProps {
	acceptAllCookies: EmptyFunction
	rejectAllCookies: EmptyFunction
	openManageDialog: boolean
	handleDialogClose: EmptyFunction
	handleDrawerClose: EmptyFunction
}

export interface ToggleState {
	strict: boolean
	performance: boolean
}

const CookiesDialog = ({ openManageDialog, handleDialogClose, acceptAllCookies, rejectAllCookies, handleDrawerClose }: CookiesDialogProps) => {
	const theme = useTheme()
	const localize = useLocalize()

	const [openCollapse, setOpenCollapse] = useState<ToggleState>({
		strict: true,
		performance: false,
	})
	const [isChecked, setIsChecked] = useState<ToggleState>({
		strict: true,
		performance: false,
	})

	const downSM = useMediaQuery(theme.breakpoints.down('sm'))
	const downMd = useMediaQuery(theme.breakpoints.down('ml'))
	const downlg = useMediaQuery(theme.breakpoints.down('lg'))

	useEffect(() => {
		const userConsentString = localStorage.getItem('userCookieConsent')
		if (userConsentString) {
			const userConsent = JSON.parse(userConsentString)
			const checkList = {
				strict: true,
				performance: userConsent?.performanceCookies === 'enabled' ? true : false,
			}
			setIsChecked(checkList)
		}
	}, [])

	const handleChange = (checked: boolean, key: string) => {
		setIsChecked({
			...isChecked,
			[key]: checked,
		})
	}

	const toggleCollapse = (key: keyof ToggleState) => {
		setOpenCollapse((prevState) => ({
			// Set all keys to false except the clicked key, which toggles
			...prevState,
			[key]: !prevState[key],
			...Object.fromEntries(
				Object.keys(prevState)
					.filter((k) => k !== key)
					.map((k) => [k, false])
			),
		}))
	}

	function onAcceptAllCookies() {
		acceptAllCookies()
		handleDialogClose()
		handleDrawerClose()
	}

	function onRejectAllCookies() {
		rejectAllCookies()
		handleDialogClose()
		handleDrawerClose()
	}

	function handleSaveSetting() {
		if (isChecked.performance && isChecked.strict) {
			onAcceptAllCookies()
		} else onRejectAllCookies()
	}

	return (
		<Dialog
			open={openManageDialog}
			PaperProps={{
				sx: {
					borderRadius: '16px',
					p: 0,
					backgroundColor: 'background.surface',
					backgroundImage: 'none',
					height: '85%',
					minWidth: '90vw',
				},
			}}
		>
			<DialogTitle sx={{ ...flexStyles.flexRowSpaceBetweenAlignCenter, gap: '10px', p: downMd ? '10px 20px' : '10px 40px' }}>
				<CustomIcon
					name={iconKeys.getFitNowIcon}
					style={{ width: downMd ? '150px' : '170px', height: downMd ? '44px' : '44px' }}
					svgStyle={downMd ? 'width:150px; height:44px' : 'width:170px; height:44px'}
				/>

				{!downSM && (
					<Typography variant={typographyKeys.h3} sx={{ color: 'text.defaultHeading' }}>
						{localize(localeKeys.consentManager)}
					</Typography>
				)}

				<IconButton size='small' style={{ height: '34px', width: '34px' }} onClick={handleDialogClose}>
					<CustomIcon name={iconKeys.closeCircle} style={{ height: '30px', width: '30px' }} svgStyle='height: 30px; width: 30px;' />
				</IconButton>
			</DialogTitle>

			<DialogContent sx={{ ...flexStyles.flexColumn, p: downMd ? '10px 20px' : '10px 40px', overflow: 'auto', gap: '25px' }}>
				<Box sx={{ ...flexStyles.flexColumn, gap: '16px' }}>
					<Typography variant={typographyKeys.h2} sx={{ color: 'text.defaultHeading' }}>
						{localize(localeKeys.privacy_intro_title)}
					</Typography>
					<Typography variant={typographyKeys.subtitle1} sx={{ color: 'text.primary' }}>
						{localize(localeKeys.privacy_intro_content)}
					</Typography>
				</Box>

				<CollapseCookieUI
					descriptionUI={
						<>
							<Typography variant={typographyKeys.subtitle1} sx={{ color: 'text.primary' }}>
								{localize(localeKeys.strictly_necessary_cookies_description)}
							</Typography>
						</>
					}
					collapse={openCollapse.strict}
					handleCollapseChange={toggleCollapse}
					data={{
						title: localeKeys.strictly_necessary_cookies_title,
					}}
					isChecked={isChecked.strict}
					handleChange={handleChange}
					itemKey='strict'
					disableCheck={true}
					cookieData={[
						{
							cookieName: localeKeys.cookie_list_cookie_name,
							cookieType: localeKeys.cookie_list_cookie_type,
							category: localeKeys.cookie_list_category,
							expirationDuration: localeKeys.cookie_list_expiration_duration,
							platformName: localeKeys.cookie_list_platform_name,
							description: localeKeys.cookie_list_cookie_description,
						},
					]}
				/>

				<CollapseCookieUI
					collapse={openCollapse.performance}
					handleCollapseChange={toggleCollapse}
					descriptionUI={
						<Box>
							<Typography variant={typographyKeys.subtitle1} sx={{ color: 'text.primary' }}>
								{localize(localeKeys.performance_cookies_description)}
							</Typography>
							<ul>
								<Typography variant={typographyKeys.subtitle1} sx={{ color: 'text.primary' }}>
									<li> {localize(localeKeys.performance_cookies_subPoint1)}</li>
									<li> {localize(localeKeys.performance_cookies_subPoint2)}</li>
									<li> {localize(localeKeys.performance_cookies_subPoint3)}</li>
								</Typography>
							</ul>

							<Typography variant={typographyKeys.subtitle1} sx={{ color: 'text.primary' }}>
								{localize(localeKeys.performance_cookies_Description2)}
							</Typography>
						</Box>
					}
					data={{
						title: localeKeys.performance_cookies_title,
					}}
					isChecked={isChecked.performance}
					handleChange={handleChange}
					itemKey='performance'
					cookieData={[
						{
							cookieName: localeKeys.performance_cookie_1_cookie_name,
							cookieType: localeKeys.performance_cookie_1_cookie_type,
							category: localeKeys.performance_cookie_1_category,
							expirationDuration: localeKeys.performance_cookie_1_expiration_duration,
							platformName: localeKeys.performance_cookie_1_platform_name,
							description: localeKeys.performance_cookie_1_cookie_description,
						},
						{
							cookieName: localeKeys.performance_cookie_2_cookie_name,
							cookieType: localeKeys.performance_cookie_2_cookie_type,
							category: localeKeys.performance_cookie_2_category,
							expirationDuration: localeKeys.performance_cookie_2_expiration_duration,
							platformName: localeKeys.performance_cookie_2_platform_name,
							description: localeKeys.performance_cookie_2_cookie_description,
						},
					]}
				/>
			</DialogContent>

			<DialogActions
				sx={
					downMd
						? { ...flexStyles.flexColumn, gap: '16px', p: '10px 30px', overflow: 'auto', ...globalStyles.hideScroll }
						: { ...flexStyles.flexRowSpaceBetweenAlignCenter, gap: '20px', p: '20px 40px', overflow: 'auto', ...globalStyles.hideScroll }
				}
			>
				<Box sx={downMd ? { ...flexStyles.flexColumn, gap: '16px' } : { ...flexStyles.flexRowSpaceBetweenAlignCenter, gap: '20px' }}>
					<CustomizedButton
						textprops={{ text: localize(localeKeys.saveSettings), variant: typographyKeys.button1, color: 'text.defaultHeading' }}
						sxprops={{
							minWidth: downlg ? '200px' : '236px',
							height: '46px',
							backgroundColor: 'background.surface',
							border: '1px solid',
							borderColor: 'border.active',
						}}
						onClick={handleSaveSetting}
						iconprops={{
							parentSx: { ...flexStyles.flexCenterSpaceBetween, width: '100%' },
							endIcon: iconKeys.rightNextroundedWhite,
						}}
					/>
					<CustomizedButton
						textprops={{
							text: localize(localeKeys.rejectAllCookiesButtonText),
							variant: typographyKeys.button1,
							color: 'text.defaultHeading',
						}}
						sxprops={{
							minWidth: downlg ? '200px' : '236px',
							height: '46px',
							backgroundColor: 'background.surface',
							border: '1px solid',
							borderColor: 'border.active',
						}}
						onClick={onRejectAllCookies}
						iconprops={{
							parentSx: { ...flexStyles.flexCenterSpaceBetween, width: '100%' },
							endIcon: iconKeys.rightNextroundedWhite,
						}}
					/>
				</Box>

				<CustomizedButton
					textprops={{ text: localize(localeKeys.acceptAllCookiesButtonText), variant: typographyKeys.button1 }}
					sxprops={{
						minWidth: downlg ? '200px' : '236px',
						height: '46px',
						backgroundColor: 'background.background2',
					}}
					onClick={onAcceptAllCookies}
					iconprops={{
						parentSx: { ...flexStyles.flexCenterSpaceBetween, width: '100%' },
						endIcon: iconKeys.rightNextroundedWhite,
					}}
				/>
			</DialogActions>
		</Dialog>
	)
}

export default CookiesDialog
