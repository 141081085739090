import {
	RemoveUpdateGames,
	AcceptRejectInviteReq,
	DeletePlaylistReq,
	FetchPlaylistReq,
	UnjoinPlaylistReq,
	GetFriendsListReq,
	AddFriendsToPlaylistReq,
	AddGameToPlaylists,
	InvitationPlaylistReq,
	UnjoinMemberRequest,
} from '../playlistDataModal'
import customFetch from '../../../../utils/customAxios'
import { apiEndPoints } from '../../../../utils/apiConstants'
import { AnyObject } from '../../../../utils/utils'

export const fetchGamePlaylistThunk = async (body: FetchPlaylistReq) => {
	const response = await customFetch.post(apiEndPoints.fetchGameplaylists, body)
	return { data: response.data }
}

export const listMembersThunk = async (body: any) => {
	const response = await customFetch.post(apiEndPoints.listMembers, body)
	return { data: response.data }
}

export const removeUpdateGamesThunk = async (body: RemoveUpdateGames, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.put(apiEndPoints.updateRemoveGamesPlaylist, body)

		return { data: response.data }
	} catch (error: any) {
		return rejectWithValue(error.errorMessage)
	}
}

export const deletePlaylistThunk = async (body: DeletePlaylistReq) => {
	const response = await customFetch.post(apiEndPoints.deletePlaylist, body)
	return { data: response.data }
}

export const unJoinPlaylistThunk = async (body: UnjoinPlaylistReq) => {
	const response = await customFetch.post(apiEndPoints.unJoinPlaylist, body)
	return { data: response.data }
}

export const acceptOrRejectInviteThunk = async (body: AcceptRejectInviteReq, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(apiEndPoints.acceptRejectPlaylistInvite, body)

		return { data: response.data }
	} catch (error: any) {
		return rejectWithValue(error.errorMessage)
	}
}

export const fetchFriendsListThunk = async (body: GetFriendsListReq, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(apiEndPoints.listMyFriends, body)
		return { data: response.data }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}

export const addFriendsToPlayListThunk = async (body: AddFriendsToPlaylistReq, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(apiEndPoints.addFriendsToPlayList, body)
		return { data: response.data }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}

export const listMyPlaylistsThunk = async (body: FetchPlaylistReq, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(apiEndPoints.listMyPlaylists, body)
		return { data: response.data }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}

export const addGameToPlaylistsThunk = async (body: AddGameToPlaylists, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(apiEndPoints.addGameToPlaylists, body)
		return { data: response.data }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}

export const fetchinvitationDetailsThunk = async (body: InvitationPlaylistReq, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(apiEndPoints.fetchinvitationDetails, body)
		return { data: response.data }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}

export const unJoinMembersThunk = async (body: UnjoinMemberRequest, { rejectWithValue }: AnyObject) => {
	try {
		const response = await customFetch.post(apiEndPoints.unJoinMembers, body)
		return { data: response.data }
	} catch (error: AnyObject) {
		return rejectWithValue(error.errorMessage)
	}
}
