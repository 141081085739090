import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
	ignoreAcceptFriendApiThunk,
	ignoreAcceptFriendEmailThunk,
	inviteAFriendThunk,
	listFriendsSentReceivedThunk,
	listMyFriendsApiThunk,
	unFriendThunk,
} from './thunk'
import { FriendsData, FriendsDialogData } from './dataModal'
import { AnyObject } from '../../utils/utils'

// Define the shape of the state for the 'friends' slice
interface FriendsState {
	friendsDialogData: null | FriendsDialogData[]
	friendsData: null | FriendsData[]
	friendsTotalCount: number
}

// Initial state for the 'friends' slice
const initialState: FriendsState = {
	friendsDialogData: null,
	friendsData: null,
	friendsTotalCount: 0,
}

// Async thunk for inviting a friend
export const inviteAfrinedApi = createAsyncThunk('friends/inviteAFrined', inviteAFriendThunk)

export const listFriendsSentReceivedApi = createAsyncThunk('friends/listFriendsSentReceived', listFriendsSentReceivedThunk)

export const ignoreAcceptFriendApi = createAsyncThunk('friends/ignoreAcceptFriend', ignoreAcceptFriendApiThunk)

export const listMyFriendsApi = createAsyncThunk('friends/listMyFriends', listMyFriendsApiThunk)

export const unFriendApi = createAsyncThunk('friends/unFriend', unFriendThunk)

export const ignoreAcceptFriendEmailApi = createAsyncThunk('friends/ignoreAcceptFriendEmail', ignoreAcceptFriendEmailThunk)

// Slice with reducers and extraReducers for handling async actions
const friendsSlice = createSlice({
	name: 'friends',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(listFriendsSentReceivedApi.fulfilled, (state, action) => {
			// state.error = null
			console.log('action.payload.data.data.friends', action.payload.data.data.friends)
			state.friendsDialogData = action.payload.data.data.friends.map((d: AnyObject) => ({
				id: d._id,
				name: d.fullName,
				email: d.emailId,
				image: d.profilePictureUrl,
			}))
			state.friendsTotalCount = action.payload.data.data.totalCount
		})
		builder.addCase(listMyFriendsApi.fulfilled, (state, action) => {
			// state.error = null
			state.friendsData = action.payload.data.data.friends.map((d: AnyObject) => ({
				id: d._id,
				name: d.fullName,
				image: d.profilePictureUrl,
				displayName: d.displayName,
				email: d.emailId,
				invitedBy: d.inviter.fullName,
			}))
		})
	},
})

export default friendsSlice.reducer
