import { lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { pathKeys, routePaths } from './routeKeys'
import LeaderBoardIndex from '../home/leaderboard/LeaderBoardIndex'
import Loader from '../custom_components/Loader'

// Lazy load all components
const ApplicationRoot = lazy(() => import('../custom_components/ApplicationRoot'))
const HomeIndex = lazy(() => import('../home/Index'))
const DashBoard = lazy(() => import('../home/dashboard/DashBoard'))
const NotFound = lazy(() => import('../custom_components/NotFound'))
const PlaylistGamesFriends = lazy(() => import('../home/games/playlist/PlaylistGamesFriends'))
const SingleGameIndex = lazy(() => import('../home/games/single_game/SingleGameIndex'))
const GamesIndex = lazy(() => import('../home/games/GamesIndex'))
const FriendsIndex = lazy(() => import('../home/friends_overall/FriendsIndex'))
const PlaylistGamePlay = lazy(() => import('../home/games/playlist/playlist_singleGame/PlaylistGamePlay'))
const AboutUsIndex = lazy(() => import('../home/about_us/AboutUsIndex'))

const combinedRoutes = (
	<Suspense fallback={<Loader />}>
		<Routes>
			{/* Dashboard Routes */}
			<Route path={pathKeys.root} element={<ApplicationRoot />}>
				<Route index element={<Navigate to={routePaths.home} replace />} />
				<Route path={pathKeys.home} element={<HomeIndex />} />
				<Route path={routePaths.games} element={<GamesIndex />} />
				<Route path={`${routePaths.games}/:playlistType/:title`} element={<PlaylistGamesFriends />} />
				<Route path={routePaths.dashboard} element={<DashBoard />} />
				<Route path={routePaths.singleGame} element={<SingleGameIndex />} />
				<Route path={routePaths.friends} element={<FriendsIndex />} />
				<Route path={routePaths.aboutUs} element={<AboutUsIndex />} />
				<Route path={routePaths.leaderboard} element={<LeaderBoardIndex />} />
				<Route path={`${routePaths.games}/:playlistType/:title/single-game`} element={<PlaylistGamePlay />} />
			</Route>

			{/* Route for Not Found page */}
			<Route path={pathKeys.notFound} element={<NotFound />} />

			{/* Redirect all unmatched routes to /not-found */}
			<Route path={pathKeys.allOther} element={<Navigate to={pathKeys.notFound} replace />} />
		</Routes>
	</Suspense>
)

export default combinedRoutes
